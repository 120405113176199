import { AxiosManager } from 'app/services/axiosManager';
import BaseApi from 'app/services/common/base-api';

class RoleManagementService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/role');
  }

  async _baseRequest({ method = 'get', url = '', body }) {
    const { callback, ...rest } = { ...body };

    try {
      const {
        data: { data },
      } = await this.axios[method](url, rest);

      callback?.();

      return data;
    } catch (err) {
      callback?.(err?.response?.data?.error || err.message);
      throw AxiosManager.stringifyError(err);
    }
  }

  updateRole({ role, callback }) {
    return this._baseRequest({ method: 'put', url: `/${role?.roleId}`, body: { callback, ...role } });
  }

  createRole({ role, callback }) {
    return this._baseRequest({ method: 'post', body: { callback, ...role } });
  }

  async getRoleById(roleId) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`/${roleId}`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async deleteRole(roleId) {
    try {
      const {
        data: { data },
      } = await this.axios.delete(`/${roleId}`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getRoles(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get('/', { params: queryParams });

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new RoleManagementService();
