import _ from 'lodash';
import { useController } from 'react-hook-form';
import * as SVG from 'app/shared-components/Icons';
import { FormControl, Select, Typography, MenuItem, Checkbox, OutlinedInput, Box } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

const ControlledSelect = ({
  name,
  label,
  control,
  required,
  multiple,
  placeholder,
  options = [],
  withCheckbox,
  iconSize = 16,
  defaultValue,
  confirmActions,
  maxValuesToSelect,
  showValuesByNumber,
  ...restProps
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  const renderOptions = (selected) => {
    if (selected.length === 0) {
      return (
        <Typography variant="body2" color="accent.500">
          {placeholder}
        </Typography>
      );
    }
    if (withCheckbox) {
      if (showValuesByNumber && selected.length > 1) {
        return `${selected.length} items selected`;
      }
      return selected.map((selectedValue) => _.find(options, { value: selectedValue }).label).join(', ');
    }
    return selected;
  };

  const renderValueMultiple = withCheckbox && multiple && renderOptions;

  return (
    <FormControl fullWidth>
      <Typography variant="caption" color="accent.500">
        {label}
        {required && '*'}
      </Typography>
      <Select
        displayEmpty
        error={!!error}
        onChange={onChange}
        multiple={multiple}
        defaultValue={defaultValue}
        value={value || defaultValue}
        renderValue={renderValueMultiple}
        input={<OutlinedInput placeholder={placeholder} />}
        IconComponent={(props) => <SVG.CommonIcons size={iconSize} iconId="cheveron-down" {...props} />}
        {...restProps}
      >
        {options.map((option, index) => {
          const checkboxChecked = withCheckbox && value && value.includes(option.value);
          const disable = value?.length >= maxValuesToSelect && !value.includes(option.value);
          return (
            <MenuItem key={index} value={option.value} disabled={disable}>
              {withCheckbox && <Checkbox checked={checkboxChecked} />}
              {option?.icon && (
                <Box marginRight="5px">
                  <FuseSvgIcon size={16}>{option?.icon}</FuseSvgIcon>
                </Box>
              )}
              <Typography variant="body2">{option.label}</Typography>
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <Typography variant="caption" color="error.main">
          {error.message}
        </Typography>
      )}
    </FormControl>
  );
};

export default ControlledSelect;
