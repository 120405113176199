import { Box, styled } from '@mui/material';
import ControlledSelect from '../ControlledSelect';
import ControlledTextField from '../ControlledTextField';

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

const TextFieldWithDropdown = ({ renderValue, options, control, fieldName, selectName, placeholder }) => (
  <Wrapper>
    <ControlledTextField
      name={fieldName}
      control={control}
      placeholder={placeholder}
      sx={{ '& .MuiOutlinedInput-root fieldset': { borderRadius: '4px 0 0 4px' } }}
    />
    <Box width="max-content">
      <ControlledSelect
        options={options}
        control={control}
        name={selectName}
        renderValue={renderValue}
        sx={{
          '&.Mui-focused': {
            backgroundColor: 'accent.100',
          },

          '&:hover': {
            backgroundColor: 'accent.100',
          },
          '& fieldset': { borderLeft: 'unset' },
          borderRadius: '0 4px 4px 0',
        }}
      />
    </Box>
  </Wrapper>
);

export default TextFieldWithDropdown;
