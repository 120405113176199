import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export default function Search({
  fullWidth,
  value,
  fontSize,
  placeholder,
  iconPosition = 'end',
  ...restProps
}) {
  const adornment = iconPosition === 'start' ? 'startAdornment' : 'endAdornment';

  return (
    <TextField
      fullWidth={fullWidth}
      type="text"
      value={value}
      sx={{
        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
          padding: iconPosition === 'start' ? '0' : 'auto',
        },
      }}
      variant="outlined"
      InputProps={{
        ...restProps,
        placeholder,
        [adornment]: (
          <InputAdornment position={iconPosition} sx={{ color: 'accent.900' }}>
            <SearchIcon fontSize={fontSize} />
          </InputAdornment>
        ),
      }}
    />
  );
}
