export const TRANSLATION_KEY = 'transaction-monitoring';

export const PATHS = {
  TRANSACTION_MONITORING: '/transaction-monitoring',
  TRANSACTION_MONITORING_RESULTS: '/transaction-monitoring-results',
  TRANSACTION_MONITORING_ADDRESS_REPORT: ':id',
  TRANSACTION_MONITORING_ADDRESS_REPORT_LOGS: ':id/logs',
  ENTITY_EXPLORER: '/entity-explorer',
  ENTITY_EXPLORER_DETAILS: '/entity-explorer/:entityId',
};

export const BLOCKCHAINS = {
  BTC: 'BTC',
  BSV: 'BSV',
  ETH: 'ETH',
  LTC: 'LTC',
  TRON: 'TRON',
  POLYGON: 'POLYGON',
  ARBITRUM: 'ARBITRUM',
  BNB: 'BNB',
  BASE: 'BASE',
  AVALANCHE: 'AVALANCHE',
  MOONBEAM: 'MOONBEAM',
};

export const searchByOptions = Object.keys(BLOCKCHAINS).map((key) => ({
  value: key,
  label: key,
  icon: `transaction-monitoring:${key.toLowerCase()}`,
}));

export const STATUSES = {
  UNKNOWN: 'UNKNOWN',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const CRYPTO_AML_TYPE = {
  TRANSACTION: 'TRANSACTION',
  WALLET: 'WALLET',
};

export const blockchainRegex = {
  [BLOCKCHAINS.BSV]: /^(1|3)[a-km-zA-HJ-NP-Z1-9]{25,34}$/,
  [BLOCKCHAINS.BTC]: /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^bc1[a-z0-9]{39,59}$/i,
  [BLOCKCHAINS.ETH]: /^(0x)?[0-9a-fA-F]{40}$/,
  [BLOCKCHAINS.LTC]: /^(L|M)[a-km-zA-HJ-NP-Z1-9]{25,34}$|^ltc1[a-z0-9]{39,59}$/i,
  [BLOCKCHAINS.TRON]: /^(T[1-9A-HJ-NP-Za-km-z]{33})$|^[0-9]{6,8}$/,
  [BLOCKCHAINS.POLYGON]: /^(0x)[0-9A-Fa-f]{40}$/,
  [BLOCKCHAINS.BNB]: /^(0x)[0-9A-Fa-f]{40}$/,
  [BLOCKCHAINS.ARBITRUM]: /^(0x)[0-9A-Fa-f]{40}$/,
  [BLOCKCHAINS.BASE]: /^(0x)[0-9A-Fa-f]{40}$/,
  [BLOCKCHAINS.AVALANCHE]: /^(X-avax)[0-9A-za-z]{39}$|^(0x)[0-9A-Fa-f]{40}$/,
  [BLOCKCHAINS.MOONBEAM]: /^(0x)[0-9A-Fa-f]{40}$/,
};

export const essentialBlockchains = ['POL', 'ARB', 'BNB', 'BASE', 'AVAX', 'GLMR'];

export const txHashRegex = /^(0x)?[a-fA-F0-9]{64}$/;
