const locale = {
  DASHBOARD: 'Dashboard',
  CLIENT: 'Client',
  USERS: 'Users',
  INVITES: 'Invites',
  PLATFORMS: 'Platforms',
  SERVICES: 'Services',
  VERIFICATIONS: 'Verifications',
  TRANSACTIONS: 'Transactions',
  APPLICANTS: 'Applicants',
  INTEGRATION: 'Integration',
  APPLICATIONS: 'Applications',
  BILLING: 'Billing',
  COMPANY: 'Company',
  SETTING: 'Setting',
  SUBTITLE: 'You can get settings there',
  TEAM: 'My team',
  WORKFLOWS: 'Workflows',
  QUESTIONNAIRES: 'Questionnaires',
  ROLE_MANAGEMENT: 'Role management',
  AML_SCAN: 'AML Scan',
  SEARCH_PAGE: 'Search Page',
  RESULTS: 'Results',
  TRANSACTION_MONITORING: 'Transaction Monitoring',
  ENTITY_EXPLORER: 'Entity Explorer',
};

export default locale;
