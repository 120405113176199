import { socketSelectors } from 'app/store/socketSlice';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useEmitter = () => {
  const socket = useSelector(socketSelectors.selectConnection);

  return useCallback(
    (event, data) => {
      return new Promise((resolve, reject) => {
        socket?.emit(event, data, (response) => {
          if (response.statusCode === 200) {
            return resolve(response);
          }

          return reject(response);
        });
      });
    },
    [socket]
  );
};
