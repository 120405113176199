import { AxiosManager } from '../../../services/axiosManager';
import BaseApi from '../../../services/common/base-api';

class TransactionMonitoringService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, process.env.REACT_APP_ADMIN_API_SERVICE);
  }

  async _baseTransactionMonitoringRequest(method = 'get', endpoint, body) {
    const { callback, ...rest } = { ...body };

    try {
      const {
        data: { data },
      } = await this.axios[method](`/crypto-aml${endpoint}`, rest);

      callback?.();

      return data;
    } catch (err) {
      callback?.(err?.response?.data?.error || err.message);
      throw AxiosManager.stringifyError(err);
    }
  }

  getEntityDetails({ id }) {
    return this._baseTransactionMonitoringRequest('get', `/entities/${id}`);
  }

  getEntitiesList(params) {
    return this._baseTransactionMonitoringRequest('get', '/entities', { params });
  }

  getAddressRiskReport(body) {
    return this._baseTransactionMonitoringRequest('post', '/search', body);
  }

  getScoreResults(params) {
    return this._baseTransactionMonitoringRequest('get', '', { params });
  }

  getAmlDetails({ amlId, type, callback }) {
    return this._baseTransactionMonitoringRequest('get', `/${amlId}/details`, { params: { type }, callback });
  }

  addMonitoring(body) {
    return this._baseTransactionMonitoringRequest('post', '/monitoring/add', body);
  }

  removeMonitoring(body) {
    return this._baseTransactionMonitoringRequest('post', '/monitoring/remove', body);
  }

  getLogHistory({ amlId }) {
    return this._baseTransactionMonitoringRequest('get', `/${amlId}/log-history`);
  }

  getPdfReport({ amlId, ...rest }) {
    return this._baseTransactionMonitoringRequest('get', `/${amlId}/pdf-report`, { params: rest });
  }

  changeStatus({ amlId, ...rest }) {
    return this._baseTransactionMonitoringRequest('put', `/${amlId}/status`, rest);
  }

  refresh({ amlId, type, callback }) {
    return this._baseTransactionMonitoringRequest('post', `/${amlId}/refresh?type=${type}`, { callback });
  }

  getComments({ amlId }) {
    return this._baseTransactionMonitoringRequest('get', `/${amlId}/comments`);
  }

  postComment({ amlId, ...rest }) {
    return this._baseTransactionMonitoringRequest('post', `/${amlId}/comments`, rest);
  }

  assignToApplicant({ amlId, ...rest }) {
    return this._baseTransactionMonitoringRequest('post', `/${amlId}/assign-to-applicant`, rest);
  }

  bulkAssignToApplicant(body) {
    return this._baseTransactionMonitoringRequest('post', '/assign-to-applicant', body);
  }
}

export default new TransactionMonitoringService();
