import { AxiosManager } from 'app/services/axiosManager';
import BaseApi from 'app/services/common/base-api';

class QuestionnaireService extends BaseApi {
  constructor() {
    super(process.env.REACT_APP_ADMIN_API_URL, 'api/web/questionnaire');
  }

  async _baseRequest({ method = 'get', url = '', body }) {
    const { callback, ...rest } = { ...body };

    try {
      const {
        data: { data },
      } = await this.axios[method](url, rest);

      callback?.();

      return data;
    } catch (err) {
      callback?.(err?.response?.data?.error || err.message);
      throw AxiosManager.stringifyError(err);
    }
  }

  updateQuestionnaire({ questionnaireId, schema, callback }) {
    return this._baseRequest({ method: 'put', url: `/${questionnaireId}`, body: { callback, ...schema } });
  }

  createQuestionnaire({ schema, callback, mode }) {
    return this._baseRequest({
      method: 'post',
      body: { callback, ...schema, ...(mode && { headers: { 'X-Workmode': mode } }) },
    });
  }

  async getQuestionnaireById(questionnaireId) {
    try {
      const {
        data: { data },
      } = await this.axios.get(`/${questionnaireId}`);
      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async deleteQuestionnaire(questionnaireId) {
    try {
      const {
        data: { data },
      } = await this.axios.delete(`/${questionnaireId}`);

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }

  async getAllQuestionnaires(queryParams) {
    try {
      const {
        data: { data },
      } = await this.axios.get('/', { params: queryParams });

      return data;
    } catch (err) {
      throw AxiosManager.stringifyError(err);
    }
  }
}

export default new QuestionnaireService();
