import { lazy } from 'react';
import i18next from 'i18next';

import { PATHS, TRANSLATION_KEY } from './constants';
import { PERMISSIONS } from '../teams/constants/permissions';

const LogsPage = lazy(() => import('./Logs'));
const ScoreRequests = lazy(() => import('./ScoreRequests'));
const EntityExplorer = lazy(() => import('./EntityExplorer'));
const TransactionMonitoring = lazy(() => import('./TransactionMonitoring'));

[('uk-UA', 'en-US')].forEach((lang) =>
  i18next.addResourceBundle(lang, TRANSLATION_KEY, require(`./i18n/${lang}`).default)
);

export const TransactionMonitoringNavigationConfig = {
  id: 'transaction-monitoring',
  title: 'Transaction Monitoring',
  translate: 'TRANSACTION_MONITORING',
  type: 'collapse',
  icon: 'navigation:transaction-monitoring',
  auth: [
    PERMISSIONS.CryptoAmlAll.value,
    PERMISSIONS.CryptoAmlSearch.value,
    PERMISSIONS.CryptoAmlList.value,
    PERMISSIONS.ListCryptoAmlEntities.value,
  ],
  children: [
    {
      id: 'search-page',
      title: 'Search Page',
      translate: 'SEARCH_PAGE',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.TRANSACTION_MONITORING,
      auth: [PERMISSIONS.CryptoAmlAll.value, PERMISSIONS.CryptoAmlSearch.value],
    },
    {
      id: 'results',
      title: 'Results',
      translate: 'RESULTS',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.TRANSACTION_MONITORING_RESULTS,
      auth: [PERMISSIONS.CryptoAmlAll.value, PERMISSIONS.CryptoAmlList.value],
    },
    {
      id: 'entities',
      title: 'Entities Explorer',
      translate: 'ENTITY_EXPLORER',
      type: 'item',
      icon: 'navigation:browser',
      url: PATHS.ENTITY_EXPLORER,
      auth: [
        PERMISSIONS.CryptoAmlAll.value,
        PERMISSIONS.ListCryptoAmlEntities.value,
        PERMISSIONS.GetCryptoAmlEntity.value,
      ],
    },
  ],
};

export const TransactionMonitoringConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [
    PERMISSIONS.CryptoAmlAll.value,
    PERMISSIONS.CryptoAmlSearch.value,
    PERMISSIONS.CryptoAmlList.value,
    PERMISSIONS.ListCryptoAmlEntities.value,
  ],
  routes: [
    {
      path: PATHS.TRANSACTION_MONITORING,
      element: <TransactionMonitoring />,
    },
    {
      path: PATHS.TRANSACTION_MONITORING_RESULTS,
      children: [
        {
          path: '',
          element: <ScoreRequests />,
        },
        {
          path: PATHS.TRANSACTION_MONITORING_ADDRESS_REPORT,
          element: <TransactionMonitoring />,
        },
        {
          path: PATHS.TRANSACTION_MONITORING_ADDRESS_REPORT_LOGS,
          element: <LogsPage />,
        },
      ],
    },
    {
      path: PATHS.ENTITY_EXPLORER,
      children: [
        { path: '', element: <EntityExplorer /> },
        { path: PATHS.ENTITY_EXPLORER_DETAILS, element: <EntityExplorer /> },
      ],
    },
  ],
};
