import { createAction, createSelector } from '@reduxjs/toolkit';
import { BaseEntityStoreBuilder } from 'app/store/helpers';

import roleManagementService from './roleManagementService';

export const ROLE_MANAGEMENT_MODULE_KEY = 'role-management';

const storeBuilder = new BaseEntityStoreBuilder(ROLE_MANAGEMENT_MODULE_KEY, roleManagementService);
const entityStore = (state) => state[ROLE_MANAGEMENT_MODULE_KEY];

export const selectors = {
  ...storeBuilder.selectors,
  selectRole: createSelector(entityStore, (state) => state.role),
  selectRoleKey: createSelector(entityStore, (state) => state.roleKey),
};

export const actions = {
  deleteRole: storeBuilder.createAction('deleteRole'),
  getRoles: storeBuilder.createAction('getRoles'),
  getRoleById: storeBuilder.createAction('getRoleById'),
  createRole: storeBuilder.createAction('createRole'),
  updateRole: storeBuilder.createAction('updateRole'),
  setRoleKey: createAction(`${ROLE_MANAGEMENT_MODULE_KEY}/setRoleKey`),
  clearRole: createAction(`${ROLE_MANAGEMENT_MODULE_KEY}/clearRole`),
};

const roleManagementSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.getRoles.pending, (state) => {
      state.role = null;
    })
    .addCase(actions.getRoles.fulfilled, (state, action) => {
      const { total, rows } = { ...action.payload };
      state.total = total;

      return storeBuilder.adapter.setAll(state, rows);
    })
    .addCase(actions.getRoleById.fulfilled, (state, action) => {
      state.role = action?.payload;
    })
    .addCase(actions.deleteRole.fulfilled, (state, { meta }) => {
      state.total -= 1;

      return storeBuilder.adapter.removeOne(state, meta.arg);
    })
    .addCase(actions.setRoleKey, (state, { payload }) => {
      state.roleKey = { itemKey: payload };
    })
    .addCase(actions.clearRole, (state) => {
      state.role = null;
    })
);

export default roleManagementSlice.reducer;
