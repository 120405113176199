import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import useLocalStorage from 'app/hooks/useLocalStorage';
import { useDraftItems } from 'app/providers/DraftItemsProvider';

const defaultSelector = createSelector(
  () => '',
  () => []
);

export const useDraftList = ({ draftListKey, draftItemKeySelector }) => {
  const { removeDraft } = useDraftItems();
  const obj = useSelector(draftItemKeySelector || defaultSelector);
  const [storedValue, setStoredValue] = useLocalStorage(draftListKey, []);

  useEffect(() => {
    if (obj?.itemKey) {
      setStoredValue((prev) => prev.filter((item) => item?.itemKey !== obj.itemKey));
      removeDraft(obj?.itemKey);
    }
  }, [obj]);

  return storedValue;
};
