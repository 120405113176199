import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { socketSelectors } from 'app/store/socketSlice';

export const useSubscription = () => {
  const socket = useSelector(socketSelectors.selectConnection);

  return useCallback((event, callback) => {
    socket?.on(event, (data) => {
      callback?.(data);
    });
  }, []);
};
