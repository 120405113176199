import { createContext, useContext } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { selectors } from 'app/store/modeSlice';
import { authSelectors } from 'app/auth/store/userSlice';

const DraftItemsContext = createContext({
  getDraft: null,
  getDrafts: null,
  setDrafts: null,
  removeDraft: null,
  updateDrafts: null,
  draftItemsKey: null,
});

export const useDraftItems = () => useContext(DraftItemsContext);

const DraftItemsProvider = ({ children, instance }) => {
  const currentMode = useSelector(selectors.selectModeType);
  const currentUserId = useSelector(authSelectors.currentUserId);
  const draftItemsKey = `${currentUserId}_${currentMode}_${instance}`;

  const getDrafts = () => {
    const items = localStorage.getItem(draftItemsKey);
    return JSON.parse(items);
  };

  const setDrafts = (drafts) => {
    localStorage.setItem(draftItemsKey, JSON.stringify(drafts));
  };

  const updateDrafts = (value) => {
    const drafts = getDrafts();
    const key = value?.itemKey || value?.id || _.uniqueId();

    const updatedDrafts = { ...drafts, [key]: { ...value, itemKey: key } };

    setDrafts(updatedDrafts);
  };

  const removeDraft = (key) => {
    const drafts = getDrafts();

    if (drafts) {
      delete drafts?.[key];

      if (Object.keys(drafts || {}) === 0) {
        localStorage.removeItem(draftItemsKey);
        return;
      }

      setDrafts(drafts);
    }
  };

  const getDraft = (key) => {
    const drafts = getDrafts();

    return drafts?.[key];
  };

  return (
    <DraftItemsContext.Provider
      value={{ setDrafts, getDraft, getDrafts, updateDrafts, removeDraft, draftItemsKey }}
    >
      {children}
    </DraftItemsContext.Provider>
  );
};

export default DraftItemsProvider;
