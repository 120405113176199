import { Typography } from '@mui/material';
import { TRANSLATION_COMMON_KEY } from 'app/configs/themesConfig';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation(TRANSLATION_COMMON_KEY);

  return (
    <div className="flex flex-1 items-center justify-center h-full ">
      <Typography color="textSecondary" variant="h5" className="m-48">
        {t('THERE_ARE_NO_ITEMS')}
      </Typography>
    </div>
  );
};
