const locale = {
  UA: 'Ukrainian',
  US: 'English',
  LOGIN: 'Login',
  REGISTER: 'Register',
  PROFILE: 'My Profile',
  LOGOUT: 'Logout',
  TIME: 'Time',

  ADMIN: 'Admin',
  MANAGER: 'Manager',
  OPERATOR: 'Operator',
  NEW_VERIFICATION: 'New verification',
  SELECT_APP: 'Application',
  SELECT_FLOW: 'Flow',
  SELECT_TTL: 'Token lifetime',
  SELECT_EXTERNAL_USER_ID: 'External user ID*',
  SELECT_LOCALE: 'Select language',
  CREATE: 'Create',
  CLIENT_SESSION: 'Client session (requestID)',
  THERE_ARE_NO_ITEMS: 'There is no data to display',

  STATUS_APP: 'Live',
  LANGUAGE: 'Language',

  MINUTE: 'minutes',
  HOUR: 'hours',
  DAY: 'days',
  WEEK: 'weeks',

  CONFIRM_DELETE: 'Are you sure you want to delete the ',
  DELETE: 'Delete',
  FORCE_DELETE: 'Force delete',
  CANCEL: 'Cancel',
  EDIT: 'Edit',
  DRAFT: 'Draft',
  SAVE: 'Save',
  BACK_TO_LIST: 'Back to list',
  DUPLICATE: 'Duplicate',

  REACHED_LIMIT_OF_TEST_VERIFICATIONS:
    'You`ve reached the limit of test verifications. Please contact support to extend it.',

  // UNSAVED CHANGES DIALOG
  YOU_HAVE_UNSAVED_CHANGES: 'You have unsaved changes',
  YOU_HAVE_UNSAVED_CHANGES_DESCRIPTION:
    'Are you sure you want to leave without saving? All your modifications will be lost.',
  LEAVE_WITHOUT_CHANGES: 'Leave Without Saving',
  KEEP_EDITING: 'Continue Editing',
};

export default locale;
