import { createSelector, createSlice } from '@reduxjs/toolkit';

const socketSlice = createSlice({
  name: 'socket',
  initialState: { connected: false, connection: null },
  reducers: {
    setConnected: (state, action) => {
      state.connected = action.payload;
    },
    setConnection: (state, action) => {
      state.connection = action.payload;
    },
  },
});

const selectSocket = (state) => state.socket;

export const socketSelectors = {
  selectConnection: createSelector(selectSocket, (socket) => socket.connection),
};
export const { setConnected, setConnection } = socketSlice.actions;
export default socketSlice.reducer;
