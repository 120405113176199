import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ACCESS_TOKEN_KEY } from 'app/services/jwtService';
import { selectors as modeSelectors } from 'app/store/modeSlice';

export const useOptions = () => {
  const currentMode = useSelector(modeSelectors.selectModeType);

  return useMemo(
    () => ({
      transports: ['websocket'],
      path: '/ws/',
      auth: { token: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`, mode: currentMode },
      reconnect: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
    }),
    [currentMode]
  );
};
