import _ from 'lodash';
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import { BaseEntityStoreBuilder } from '../../../store/helpers';
import { actions as logsActions } from '../../logs/store/logsSlice';

import { SERVICES, APPLICANT_MODULE_KEY } from '../constants';
import applicantsService from './applicantsService';
import { actions as historyActions } from './historySlice';
import { actions as serviceActions } from './servicesSlice';
import { actions as documentCheckActions } from './DocumentCheckSlice';

const storeBuilder = new BaseEntityStoreBuilder(
  APPLICANT_MODULE_KEY,
  applicantsService,
  (applicant) => applicant.id
);

const entityStore = (state) => state[APPLICANT_MODULE_KEY];

export const selectors = {
  ...storeBuilder.selectors,
  selectById: (id) => (state) => storeBuilder.selectors.selectById(state, id),
  selectDeviceGeoData: createSelector(entityStore, (state) => state.deviceGeoData),
  selectDeviceGeoList: createSelector(entityStore, (state) => state.deviceGeoList),
  selectIsReviewerAssigned: createSelector(entityStore, (state) => state.isReviewerAssigned),
};

export const actions = {
  getAllApplicants: storeBuilder.actions.getAll,
  getApplicantById: createAsyncThunk(`${APPLICANT_MODULE_KEY}/getById`, async (id, { dispatch }) => {
    const response = await applicantsService.getById(id);
    const { servicesData, histories, webhookDeliveryState, error, ...restApplicant } = { ...response };

    if (servicesData) {
      dispatch(serviceActions.fillDataServices(servicesData));
      const documentCapture = servicesData.find(({ service }) => service === SERVICES.DOCUMENT_CAPTURE);
      if (documentCapture && !_.isEmpty(documentCapture?.data)) {
        const test = documentCapture?.data
          .map(({ fields }, index) => fields.map((item) => ({ id: `${index}-${item.fieldType}`, ...item })))
          .flat();
        dispatch(documentCheckActions.fillDocumentCheckFields(test));
      }
    }
    if (error) {
      dispatch(serviceActions.fillError(error));
    }
    dispatch(serviceActions.fillInfo(restApplicant));
    return restApplicant;
  }),
  assignReviewer: createAsyncThunk(
    `${APPLICANT_MODULE_KEY}/assignReviewer`,
    async ({ id, callback, ...restParams }, { dispatch }) => {
      const response = await applicantsService.assignReviewer({ id, callback, ...restParams });
      const {
        changes: { review },
      } = { ...response };

      dispatch(serviceActions.updateInfo({ review }));

      return response;
    }
  ),
  batchAssign: storeBuilder.createAction('batchAssign'),
  approve: storeBuilder.createAction('approve'),
  decline: storeBuilder.createAction('decline'),
  deleteApplicant: storeBuilder.actions.delete,
  getLogs: createAsyncThunk(`${APPLICANT_MODULE_KEY}/getLogs`, async ({ id, queryParams }, { dispatch }) => {
    const logs = await applicantsService.getLogs({ id, queryParams });

    const { offset } = queryParams;
    if (logs && offset === 0) {
      dispatch(logsActions.setLogs(logs));
    }
    if (logs && offset > 0) {
      dispatch(logsActions.addLogs(logs));
    }
  }),
  getHistory: createAsyncThunk(
    `${APPLICANT_MODULE_KEY}/getHistory`,
    async ({ id, service }, { dispatch }) => {
      dispatch(historyActions.setHistoryLoadingStatus(true));
      const history = await applicantsService.getHistory({ id, service });
      if (history) {
        dispatch(historyActions.fillHistory(history));
      }
    }
  ),
  moveToFlow: createAsyncThunk(`${APPLICANT_MODULE_KEY}/moveToFlow`, async ({ id, flowId }, { dispatch }) => {
    const response = await applicantsService.moveToFlow({
      id,
      flowId,
    });
    const { servicesData, flow, status, review, amlMonitoring } = { ...response };

    dispatch(serviceActions.fillDataServices(servicesData));
    dispatch(serviceActions.updateInfo({ flow, status, review, amlMonitoring }));

    return response;
  }),
  resolveWarnings: createAsyncThunk(
    `${APPLICANT_MODULE_KEY}/resolveWarnings`,
    async ({ applicantId, body }, { dispatch }) => {
      const review = await applicantsService.resolveWarnings({
        applicantId,
        body,
      });

      dispatch(serviceActions.updateInfo({ review }));
    }
  ),
  scheduleApplicantDeletion: storeBuilder.createAction('scheduleApplicantDeletion'),
  restoreApplicant: storeBuilder.createAction('restoreApplicant'),
  deactivateService: createAsyncThunk(
    `${APPLICANT_MODULE_KEY}/deactivateService`,
    async ({ id, service, comment }, { dispatch }) => {
      const { applicant, deactivatedService } = await applicantsService.deactivateService({
        id,
        service,
        comment,
      });

      dispatch(historyActions.addToHistory({ ...deactivatedService, comment }));
      dispatch(serviceActions.fillDataServices(applicant?.servicesData));
    }
  ),
  activateService: createAsyncThunk(
    `${APPLICANT_MODULE_KEY}/activateService`,
    async ({ id, service, comment, serviceId, callback }, { dispatch }) => {
      const { servicesData } = await applicantsService.activateService({
        id,
        service,
        comment,
        serviceId,
        callback,
      });

      dispatch(historyActions.removeFromHistory(serviceId));
      dispatch(serviceActions.fillDataServices(servicesData));
    }
  ),
  updateFullName: storeBuilder.createAction('updateFullName'),
  getDeviceGeo: storeBuilder.createAction('getDeviceGeo'),
  getDeviceGeoById: storeBuilder.createAction('getDeviceGeoById'),
  restartService: storeBuilder.createAction('restartService'),
};

const applicantsSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.approve.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.decline.fulfilled, storeBuilder.adapter.updateOne)
    .addCase(actions.batchAssign.fulfilled, (state, { payload }) => {
      state.isReviewerAssigned = { status: true };
      return storeBuilder.adapter.updateMany(state, payload);
    })
    .addCase(actions.assignReviewer.fulfilled, (state, action) => {
      storeBuilder.adapter.updateOne(state, action?.payload);
    })
    .addCase(actions.scheduleApplicantDeletion.fulfilled, (state, action) => {
      const { id, scheduledDeletionDate } = { ...action.payload };
      return storeBuilder.adapter.updateOne(state, { id, changes: { scheduledDeletionDate } });
    })
    .addCase(actions.restoreApplicant.fulfilled, (state, action) => {
      const { id, scheduledDeletionDate } = { ...action.payload };
      return storeBuilder.adapter.updateOne(state, { id, changes: { scheduledDeletionDate } });
    })
    .addCase(actions.moveToFlow.fulfilled, (state, action) => {
      storeBuilder.adapter.updateOne(state, { id: action.payload?.id, changes: action.payload });
    })
    .addCase(actions.updateFullName.fulfilled, (state, action) => {
      const { fullName, id } = action.payload;
      return storeBuilder.adapter.updateOne(state, { id, changes: { fullName } });
    })
    .addCase(actions.getDeviceGeo.fulfilled, (state, action) => {
      state.deviceGeoList = action.payload.rows;
    })
    .addCase(actions.getDeviceGeoById.fulfilled, (state, action) => {
      state.deviceGeoData = action.payload;
    })
);

export default applicantsSlice.reducer;
