export const LOGS_MODULE_KEY = 'logs';
export const TRANSLATION_KEY = 'logs-translations';
export const PUBLISHER_TYPES = {
  APPLICANT: 'applicant',
  USER: 'user',
  APPLICATION: 'application',
  SYSTEM: 'system',
};

export const APPLICANT_HISTORY_EVENT_NAME = {
  // AML
  AML_STEP: 'AML_STEP',
  AML_NO_MATCH: 'AML_NO_MATCH',
  AML_PROFILE_ADDED: 'AML_PROFILE_ADDED',
  AML_START_PROCESS: 'AML_START_PROCESS',
  AML_EXPORTED_DATA: 'AML_EXPORTED_DATA',
  AML_STATUS_UPDATED: 'AML_STATUS_UPDATED',
  AML_PROFILE_DELETED: 'AML_PROFILE_DELETED',
  AML_PROFILE_UPDATED: 'AML_PROFILE_UPDATED',
  AML_MONITORING_SET_UP: 'AML_MONITORING_SET_UP',
  AML_MONITORING_ENABLED: 'AML_MONITORING_ENABLED',
  AML_MONITORING_UPDATED: 'AML_MONITORING_UPDATED',
  AML_MONITORING_DISABLED: 'AML_MONITORING_DISABLED',
  AML_MATCH_STATUS_UPDATED: 'AML_MATCH_STATUS_UPDATED',
  AML_PROFILE_STATUS_CHANGED: 'AML_PROFILE_STATUS_CHANGED',
  AML_PROFILE_STATUS_UPDATED: 'AML_PROFILE_STATUS_UPDATED',
  CREATED_AML_PROFILE_COMMENT: 'CREATED_AML_PROFILE_COMMENT',
  APPLICANT_SERVICE_ACTIVATED: 'APPLICANT_SERVICE_ACTIVATED',

  APPLICANT_UPDATE_NAME: 'APPLICANT_UPDATE_NAME',
  ADD_USER_REQUEST_METADATA: 'ADD_USER_REQUEST_METADATA',
  UPLOADED_FILES: 'UPLOADED_FILES',
  VERIFICATION_ERROR: 'VERIFICATION_ERROR',
  START_NEW_VERIFICATION: 'START_NEW_VERIFICATION',
  APPLICANT_DOCUMENT_TYPES_UPDATED: 'APPLICANT_DOCUMENT_TYPES_UPDATED',
  APPLICANT_DOCUMENT_FIELDS_UPDATED: 'APPLICANT_DOCUMENT_FIELDS_UPDATED',
  APPLICANT_SERVICE_DEACTIVATED: 'APPLICANT_SERVICE_DEACTIVATED',
  APPLICANT_FLOW_UPDATED: 'APPLICANT_FLOW_UPDATED',
  APPLICANT_RESTORE: 'APPLICANT_RESTORE',
  SCHEDULE_APPLICANT_DELETION: 'SCHEDULE_APPLICANT_DELETION',
  APPLICANT_CREATED: 'APPLICANT_CREATED',
  FACE_MATCH_STEP: 'FACE_MATCH_STEP',
  LIVENESS_STEP: 'LIVENESS_STEP',
  LIVENESS_STEP_ADDED_VIDEO: 'LIVENESS_STEP_ADDED_VIDEO',
  DOCUMENT_STEP: 'DOCUMENT_STEP',
  SELFIE_STEP: 'SELFIE_STEP',
  OTP_STEP_SENT: 'OTP_STEP_SENT',
  OTP_STEP_CHECKED: 'OTP_STEP_CHECKED',
  QUESTIONNAIRE_STEP: 'QUESTIONNAIRE_STEP',
  REVIEWER_ASSIGNED: 'REVIEWER_ASSIGNED',
  REVIEW_DECLINED_BY_REVIEWER: 'REVIEW_DECLINED_BY_REVIEWER',
  REVIEW_APPROVED_BY_REVIEWER: 'REVIEW_APPROVED_BY_REVIEWER',
  REVIEW_DECLINED_BY_SYSTEM: 'REVIEW_DECLINED_BY_SYSTEM',
  REVIEW_APPROVED_BY_SYSTEM: 'REVIEW_APPROVED_BY_SYSTEM',
  REVIEW_COMPLETED_BY_SYSTEM: 'REVIEW_COMPLETED_BY_SYSTEM',
  REVIEW_STATUS_CHANGED_BY_SYSTEM: 'REVIEW_STATUS_CHANGED_BY_SYSTEM',
  REVIEW_WARNING_SET_BY_SYSTEM: 'REVIEW_WARNING_SET_BY_SYSTEM',
  REVIEW_WARNING_RESOLVED_BY_SYSTEM: 'REVIEW_WARNING_RESOLVED_BY_SYSTEM',
  REVIEW_WARNING_RESOLVED: 'REVIEW_WARNING_RESOLVED',
  FRONT_END_EVENT: 'FRONT_END_EVENT',
  WS_DISCONNECT: 'WS_DISCONNECT',
  WS_NEW_CLIENT_CONNECTED: 'WS_NEW_CLIENT_CONNECTED',
  APPLICANT_COUNTRY_RESTRICTION: 'APPLICANT_COUNTRY_RESTRICTION',

  INTRO_OPEN: 'INTRO_OPEN',
  INTRO_STEP: 'INTRO_STEP',
  DOC_PHOTO_CAMERA_ERROR: 'DOC_PHOTO_CAMERA_ERROR',
  DOC_PHOTO_CAMERA: 'DOC_PHOTO_CAMERA',
  SELFIE_CAMERA_OPENED: 'SELFIE_CAMERA_OPENED',
  DOC_PHOTO_RETAKE: 'DOC_PHOTO_RETAKE',
  DOC_CAPTURE_IMAGE: 'DOC_CAPTURE_IMAGE',
  DOC_TYPE_SUBMITTED: 'DOC_TYPE_SUBMITTED',
  DOC_STEP_SEND: 'DOC_STEP_SEND',
  DOC_CLOSE_CAMERA: 'DOC_CLOSE_CAMERA',
  DOC_STEP_OPEN: 'DOC_STEP_OPEN',
  DOC_CAMERA_CLOSED: 'DOC_CAMERA_CLOSED',
  DOC_FILE_PROCESS_STARTED: 'DOC_FILE_PROCESS_STARTED',
  LIVENESS_STEP_OPEN: 'LIVENESS_STEP_OPEN',
  LIVENESS_START: 'LIVENESS_START',
  LIVENESS_STEP_OPEN_ERROR: 'LIVENESS_STEP_OPEN_ERROR',
  LIVENESS_SENT: 'LIVENESS_SENT',
  LIVENESS_ERROR_PROCESSING: 'LIVENESS_ERROR_PROCESSING',
  LIVENESS_NO_CAMERA_AVAILABLE: 'LIVENESS_NO_CAMERA_AVAILABLE',
  OTP_SENT_CODE: 'OTP_SENT_CODE',
  OTP_STEP_OPEN: 'OTP_STEP_OPEN',
  QUESTIONNAIRE_STEP_OPEN: 'QUESTIONNAIRE_STEP_OPEN',
  SELFIE_STEP_OPEN: 'SELFIE_STEP_OPEN',
  SELFIE_OPEN_CAMERA: 'SELFIE_OPEN_CAMERA',
  SELFIE_RETAKE: 'SELFIE_RETAKE',
  SELFIE_READY_FOR_NEXT: 'SELFIE_READY_FOR_NEXT',
  SELFIE_CAMERA_CLOSE: 'SELFIE_CAMERA_CLOSE',

  EMAIL_VERIFICATION_STEP: 'EMAIL_VERIFICATION_STEP',
  EMAIL_VERIFICATION_STEP_OTP_SENT: 'EMAIL_VERIFICATION_STEP_OTP_SENT',
  PHONE_VERIFICATION_STEP: 'PHONE_VERIFICATION_STEP',
  PHONE_VERIFICATION_STEP_OTP_SENT: 'PHONE_VERIFICATION_STEP_OTP_SENT',

  // Crypto AML
  CRYPTO_AML_STATUS_UPDATED: 'CRYPTO_AML_STATUS_UPDATED',
  CRYPTO_AML_EXPORTED_DATA: 'CRYPTO_AML_EXPORTED_DATA',
  CRYPTO_CREATED_AML_COMMENT: 'CRYPTO_CREATED_AML_COMMENT',
  CRYPTO_AML_MONITORING_DISABLED: 'CRYPTO_AML_MONITORING_DISABLED',
  CRYPTO_AML_MONITORING_ENABLED: 'CRYPTO_AML_MONITORING_ENABLED',
  CRYPTO_AML_START_PROCESS: 'CRYPTO_AML_START_PROCESS',
  CRYPTO_RETRIEVED_AML_RESULT: 'CRYPTO_RETRIEVED_AML_RESULT',
  CRYPTO_RETRIEVED_AML_RESULT_ERROR: 'CRYPTO_RETRIEVED_AML_RESULT_ERROR',
  CRYPTO_AML_ASSIGNED_TO_APPLICANT: 'CRYPTO_AML_ASSIGNED_TO_APPLICANT',
  CRYPTO_AML_REFRESH_PROCESS: 'CRYPTO_AML_REFRESH_PROCESS',
  ADDRESS_RISK_SCORE_STEP: 'ADDRESS_RISK_SCORE_STEP',
  ADDRESS_RISK_SCORE_STEP_START: 'ADDRESS_RISK_SCORE_STEP_START',
};
