import { useEffect, useState } from 'react';

export default (key, initValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const value = localStorage.getItem(key);
    return value ? Object.values(JSON.parse(value)) : initValue;
  });

  useEffect(() => {
    const value = localStorage.getItem(key);

    setStoredValue(value ? Object.values(JSON.parse(value)) : initValue);
  }, [key]);

  return [storedValue, setStoredValue];
};
