import { cloneElement } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import Search from '../Search';
import useFilters from './useFilters';
import DateRangePicker from '../DateRangePicker';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '100%',
}));

const Filters = ({
  onFiltersChange,
  appendFilters,
  defaultFilters,
  fetchAction,
  placeholders = { search: 'Search' },
}) => {
  const { onDateChange, onSearch, onChange } = useFilters({
    defaultFilters,
    fetchAction,
    onFiltersChange,
  });

  return (
    <Container>
      <Box display="flex" justifyContent="space-between">
        <DateRangePicker
          onChange={onDateChange}
          defaultValue={{
            endDate: defaultFilters?.endDate && moment(defaultFilters?.endDate),
            startDate: defaultFilters?.startDate && moment(defaultFilters?.startDate),
          }}
        />
        <Search
          size="small"
          fontSize="small"
          onChange={onSearch}
          iconPosition="start"
          placeholder={placeholders.search}
          defaultValue={defaultFilters?.search}
        />
      </Box>
      {appendFilters && cloneElement(appendFilters, { onFiltersChange: onChange, ...defaultFilters })}
    </Container>
  );
};

export default Filters;
