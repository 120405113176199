export default {
  PROFILE: 'Profile',
  MEDIUM: 'Medium',
  LOW: 'Low',
  HIGH: 'High',
  TIMELINE: 'Timeline',
  BACK_TO_RESULT: 'Back to result',
  CONFIRM: 'Confirm',
  DOWNLOAD_PDF: 'Download PDF',
  MONITORING: 'Monitoring',

  // INFORMATION
  ADDRESS: 'Address',
  AUTHOR: 'Author',
  BLOCKCHAIN: 'Blockchain',
  BALANCE: 'Balance',
  REPORT_GENERATED: 'Report Generated',
  ADDRESS_INFORMATION: 'Address information',
  ADDRESS_RISK_REPORT: 'Address Risk Report',
  ADD_TO_MONITORING: 'Add to monitoring',
  REFRESH: 'Refresh',
  TRANSACTION_RECEIVED: 'Transaction Received',
  TOTAL_RECEIVED: 'Total Received',
  FIRST_SEEN: 'First Seen',
  TOTAL_TRANSACTIONS: 'Total Transactions',
  TRANSACTIONS_SENT: 'Transactions Sent',
  TOTAL_SENT: 'Total Sent',
  LAST_SEEN: 'Last Seen',
  TRANSACTION_INFORMATION: 'Transaction Information',
  BLOCK: 'Block',
  TIMESTAMP: 'Timestamp',
  TRANSACTION_HASH: 'Transaction Hash',
  TOTAL_AMOUNT: 'Total Amount',

  // GL_SCORE
  GL_SCORE: 'GL Score',
  SOURCE_OF_FUNDS: 'Source of Funds',
  USE_OF_FUNDS: 'Use of Funds',
  ADDRESS_RISK: 'Address Risk',

  // RISK_EXPOSURE
  RISK_EXPOSURE: 'Risk Exposure',
  FULL_EXPOSURE: 'Full Exposure',
  RISK_LABELS: 'Risk Labels',
  ENTITY: 'Entity',
  HIGHEST_AMOUNT: 'Highest amount',
  LOWEST_AMOUNT: 'Lowest amount',
  HIGHEST_RISK: 'Highest risk',
  LOWEST_RISK: 'Lowest risk',
  LABEL_NAME_DESC: 'Label name (desc)',
  LABEL_NAME_ASC: 'Label name (asc)',
  EVALUATED_TRANSACTIONS: 'Evaluated Transactions',
  SOURCE_OF_FUNDS_TRANSACTIONS: 'Source of Funds Transactions',
  USE_OF_FUNDS_TRANSACTIONS: 'Use of Funds Transactions',
  RISK_LABEL: 'Risk label',
  AMOUNT: 'Amount',
  LEAF_TX: 'Leaf Tx',
  ROOT_TX: 'Root Tx',
  DEPTH: 'Depth',
  DATE_AND_TIME: 'Date & Time',

  // ENTITY_EXPLORER
  OVERVIEW: 'Overview',
  ENTITY_EXPLORER: 'Entity Explorer',
  ENTITY_NAME: 'Entity name',
  LEGAL_NAME: 'Legal Name',
  PARENT_COMPANY_OR_OWNERSHIP: 'Parent company / ownership',
  ENTITY_WEBSITE: 'Entity website',
  DOMICILED_COUNTRY: 'Domiciled Country',
  STATUS: 'Status',
  TYPE: 'Type',
  ASSOCIATED_TAGS: 'Associated tags',
  DESCRIPTION: 'Description',
  ENTITY_DETAILS: 'Entity details',
  PROVIDER_SERVICES: 'Provided Services',
  PRIMARY_OPERATIONAL_REGIONS: 'Primary operational regions',
  RESTRICTED_JURISDICTIONS: 'Restricted Jurisdictions',
  FIAT_CURRENCY_TRADING: 'Fiat currency trading',
  OFFICE_ADDRESSES: 'Office addresses',
  LANGUAGES: 'Languages',
  PRIVACY_COINS_SUPPORTED_FLAG: 'Privacy coins supported flag',
  SOCIAL_NETWORK_LINK: 'Social Network link',
  REGULATORY_COMPLIANCE: 'Regulatory Compliance',
  LOCAL_AUTHORITY: 'Local authority',
  LICENSE_NUMBER: 'License number',
  COUNTRY: 'Country',
  LICENSE_TYPE: 'License type',
  REGISTERED_NUMBER: 'Registered number',
  START_DATE: 'Start date',
  PAYMENT_SERVICE: 'Payment service',
  SYSTEM_NAME: 'System name',
  PAYMENT_TYPE: 'Payment type',
  PAYMENT_METHODS: 'Payment methods',
  WEBSITE: 'Website',
  CONTACTS: 'Contacts',
  PEOPLE: 'People',
  POSITION: 'Position',
  NAME: 'Name',
  DEPARTMENTS: 'Departments',
  DEPARTMENT_NAME: 'Department name',
  ENTER_THE_ENTITY_NAME: 'Enter the entity name',
  SEARCH_RETURN_NO_RESULTS: 'The search did not return any results',
  TOKEN_BALANCE: 'Token balance',
  ACCOUNT_HOLDER: 'Account holder',

  SEARCH: 'Search',
  TRANSACTION_MONITORING: 'Transaction Monitoring',
  HEADER_TITLE: 'Monitor and Secure Transactions with',
  HEADER_TIP:
    'Fill in the fields below and start tracking wallet activity to detect and address potential risks effectively.',

  SCAN_RESULT: 'Scan Result',
  NO_SEARCH_RESULTS: 'No results found',
  NO_SEARCH_RESULTS_DESCRIPTION:
    'Unfortunately, your search did not return any results. Try different criteria or check your spelling',

  TYPE_TO_SEARCH_RISK_REPORT:
    'Type address or transaction hash in the search bar above to get sources of funds report.',

  // Score requests
  SCORE_REQUESTS: 'Score requests',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  UNKNOWN: 'Unknown',
  TRANSACTION: 'Transaction scoring',
  WALLET: 'Address scoring',
  ALL_ASSETS: 'All assets',
  OTHER_ASSETS: 'Other assets',
  ALL_STATUS: 'All statuses',
  ALL_BLOCKCHAINS: 'All blockchains',
  SCORE: 'Score',
  TOKEN: 'Token',
  ADD_WALLET_ADDRESS_OR_TRANSACTION: 'Add wallet address or transaction',

  START_MONITORING: 'Start monitoring',
  STOP_MONITORING: 'Stop monitoring',

  SEARCH_STARTED:
    "Your transaction search is underway! We're analyzing the data and will notify you as soon as the results are ready.",
  SEARCH_COMPLETED: 'Your transaction search is complete! The results are now available for you to review.',

  // Monitoring
  REMOVE_FROM_MONITORING: 'Remove from monitoring',
  REMOVE_MONITORING: 'Confirm Monitoring Deactivation',
  REMOVE_MONITORING_DESCRIPTION:
    'You are about to cancel ongoing monitoring for this wallet/transaction. Once canceled, you will no longer receive alerts or risk assessments for compliance tracking.',
  ADD_MONITORING: 'Confirm Monitoring Activation',
  ADD_MONITORING_DESCRIPTION:
    'You are about to activate ongoing monitoring for this wallet/transaction. This will enable real-time alerts to ensure compliance with regulatory requirements.',
  MONITORING_ADDED: 'Transaction/ wallet monitoring has been successfully initiated.',
  MONITORING_REMOVED: 'Transaction/wallet monitoring has been stopped. ',

  // Change status
  CONFIRM_CHANGE_STATUS: 'Confirm Change Status',
  CONFIRM_CHANGE_STATUS_DESCRIPTION: 'Are you sure you want to change the  status for this address?',

  MONITORED: 'Monitored',
  MONITORED_TOOLTIP:
    'This wallet address is being monitored, you are able to receive fast and detailed reports as well as alerts in case of new incoming transactions, new counterparties, or AML/CTF risks identified. This report was generated by using the last 1000 incoming transactions of the requested address.',

  // Assign to applicant
  ASSIGN: 'Assign',
  ASSIGN_TO_APPLICANT: 'Assign to applicant',
  ASSIGN_TO_APPLICANT_DESCRIPTION:
    'Links wallet address to an applicant, ensuring accurate tracking and clear responsibility.',
  EXTERNAL_USER_ID: 'External user ID',
  SUCCESSFULLY_ASSIGNED: 'Successfully assigned',
  APPLICANT: 'Applicant',

  CRYPTO_AML_STATUS_UPDATED: 'The Crypto AML status for the applicant has been updated',
  CRYPTO_AML_EXPORTED_DATA: 'The Crypto AML-related data for the applicant has been exported successfully',
  CRYPTO_CREATED_AML_COMMENT: 'A new comment regarding the applicant’s Crypto AML status has been added',
  CRYPTO_AML_MONITORING_DISABLED: 'Crypto AML monitoring for the applicant has been turned off',
  CRYPTO_AML_MONITORING_ENABLED: 'Crypto AML monitoring for the applicant has been activated',
  CRYPTO_AML_START_PROCESS: 'The Crypto AML process for the applicant has been initiated',
  CRYPTO_RETRIEVED_AML_RESULT: 'The Crypto AML results for the applicant have been successfully retrieved',
  CRYPTO_RETRIEVED_AML_RESULT_ERROR:
    'An error occurred while attempting to retrieve the Crypto AML results for the applicant',
  CRYPTO_AML_ASSIGNED_TO_APPLICANT: 'This Crypto AML case has been linked to the applicant',
  CRYPTO_AML_REFRESH_PROCESS:
    'The Crypto AML process for the applicant has been refreshed for updated results',

  SCORE_IS_OUTDATED: 'The score is out of date',
  LAST_UPDATED: 'Last Update',
};
