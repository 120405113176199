const locale = {
  UA: 'Українська',
  US: 'Англійська',
  LOGIN: 'Логін',
  REGISTER: 'Зареєструватися',
  PROFILE: 'Мій профіль',
  LOGOUT: 'Вийти',
  TIME: 'Час',

  ADMIN: 'Адмін',
  MANAGER: 'Менеджер',
  OPERATOR: 'Оператор',
  NEW_VERIFICATION: 'Нова верифікація',
  SELECT_APP: 'Додаток',
  SELECT_FLOW: 'Флоу',
  SELECT_TTL: 'Час життя токена',
  SELECT_EXTERNAL_USER_ID: 'ID зовнішнього користувача*',
  SELECT_LOCALE: 'Виберіть мову',
  CREATE: 'Створити',
  CLIENT_SESSION: 'Сесія кліента (requestID)',
  THERE_ARE_NO_ITEMS: 'Немає даних для відображення',

  STATUS_APP: 'Live',
  LANGUAGE: 'Мова',

  MINUTE: 'хвилин',
  HOUR: 'годин',
  DAY: 'днів',
  WEEK: 'тижнів',

  CONFIRM_DELETE: 'Ви впевнені, що хочете видалити ',
  DELETE: 'Видалити',
  FORCE_DELETE: 'Примусово видалити',
  CANCEL: 'Скасувати',
  EDIT: 'Редагувати',
  DRAFT: 'Чернетка',
  SAVE: 'Зберегти',
  BACK_TO_LIST: 'Повернутися до списку',
  DUPLICATE: 'Дублікат',

  REACHED_LIMIT_OF_TEST_VERIFICATIONS:
    'Ви досягли ліміту тестових перевірок. Будь ласка, зверніться до служби підтримки, щоб продовжити його.',

  // UNSAVED CHANGES DIALOG
  YOU_HAVE_UNSAVED_CHANGES: 'У вас є незбережені зміни',
  YOU_HAVE_UNSAVED_CHANGES_DESCRIPTION:
    'Ви впевнені, що хочете вийти без збереження? Усі ваші зміни буде втрачено.',
  LEAVE_WITHOUT_CHANGES: 'Вийти без збереження',
  KEEP_EDITING: 'Продовжити редагування',
};

export default locale;
