import { createAction, createSelector } from '@reduxjs/toolkit';

import applicationsService from './applicationsService';
import { BaseEntityStoreBuilder } from '../../../store/helpers';

export const APPLICATIONS_MODULE_KEY = 'applications';

const storeBuilder = new BaseEntityStoreBuilder(
  APPLICATIONS_MODULE_KEY,
  applicationsService,
  (app) => app.key
);

const entityStore = (state) => state[APPLICATIONS_MODULE_KEY];

export const selectors = {
  ...storeBuilder.selectors,
  selectApplication: createSelector(entityStore, (state) => state.application),
  selectLoadingApplication: createSelector(entityStore, (state) => state.loadingApplication),
};

export const actions = {
  getApplicationById: storeBuilder.createAction('getApplicationById'),
  getAllApplications: storeBuilder.createAction('getAllApplications'),
  createApplication: storeBuilder.createAction('createApplication'),
  clearData: createAction(`${APPLICATIONS_MODULE_KEY}/clearData`),
  deleteApplication: storeBuilder.actions.delete,
  forceDeleteApp: storeBuilder.createAction('forceDelete'),
  updateApplication: storeBuilder.createAction('updateApplication'),
  updateSecretKey: storeBuilder.createAction('updateSecretKey'),
  updateApplicationByKey: createAction(`${APPLICATIONS_MODULE_KEY}/updateOneByKey`),
  createRSAKey: storeBuilder.createAction('addRSAKey'),
  deleteRSAKey: storeBuilder.createAction('deleteRSAKey'),
  updateApplicationLogo: storeBuilder.createAction('updateLogo'),
  clearErrors: createAction(`${APPLICATIONS_MODULE_KEY}/clearError`),
};

const applicationsSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.updateApplicationByKey, storeBuilder.adapter.upsertOne)
    .addCase(actions.forceDeleteApp.fulfilled, storeBuilder.adapter.removeOne)
    .addCase(actions.forceDeleteApp.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    })
    .addCase(actions.updateSecretKey.fulfilled, (state, action) => {
      state.application = { ...state.application, secretKey: action.payload.changes.secretKey };
    })
    .addCase(actions.updateApplication.fulfilled, (state, action) => {
      state.application = { ...state.application, ...action.payload.changes };
    })
    .addCase(actions.updateApplicationLogo.fulfilled, (state, action) => {
      state.application = { ...state.application, logo: action.payload.changes.logo };
    })
    .addCase(actions.createRSAKey.fulfilled, (state, action) => {
      state.successCreateRSAKey = true;
      storeBuilder.adapter.upsertOne(state, action.payload);
    })
    .addCase(actions.createRSAKey.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    })
    .addCase(actions.createRSAKey.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(actions.deleteRSAKey.fulfilled, storeBuilder.adapter.upsertOne)
    .addCase(actions.clearErrors, (state) => {
      state.error = null;
    })
    .addCase(actions.clearData, (state) => {
      state.isFulfilled = false;
      state.appKey = undefined;
    })
    .addCase(actions.createApplication.fulfilled, (state, action) => {
      state.appKey = action.payload.key;
      storeBuilder.adapter.addOne(state, action.payload);
      state.application = action.payload;
    })
    .addCase(actions.getApplicationById.pending, (state) => {
      state.loadingApplication = true;
    })
    .addCase(actions.getApplicationById.fulfilled, (state, action) => {
      state.loadingApplication = false;
      state.application = action.payload;
    })
    .addCase(actions.getAllApplications.pending, (state) => {
      if (state.application) {
        state.application = null;
      }
    })
    .addCase(actions.getAllApplications.fulfilled, (state, action) =>
      storeBuilder.adapter.setAll(state, action.payload.rows)
    )
);

export default applicationsSlice.reducer;
