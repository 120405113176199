const locale = {
  TIME: 'Час',
  TRACKER: 'Трекер',
  TYPE: 'Тип',
  TEST: 'Тест',
  LIVE: 'Наживо',
  NAME: 'Назва',
  STYLE: 'Стиль',
  SELFIE: 'Селфі',
  SAVE: 'Зберегти',
  CUSTOM: 'Власний',
  EDIT: 'Редагувати',
  MOBILE: 'Мобільний',
  PALETTE: 'Палітра',
  PERIOD: 'Період',
  DELETE: 'Видалити',
  BUTTON: 'Кнопки',
  COUNTRIES: 'Країни',
  GENERAL: 'Загальні',
  CANCEL: 'Скасувати',
  DUPLICATE: 'Дублікат',
  COMPLETE: 'Завершено',
  CONFIRM: 'Підтвердити',
  AML_CHECK: 'AML перевірка',
  WEB_CAMERA: 'Web камера',
  TYPOGRAPHY: 'Шрифти',
  QUESTIONNAIRE: 'Опитувальник',
  DOCUMENTATION: 'Документація',
  AUTOMATIC_AML_STOP_FOR_ALL_DOCS_DESCRIPTION:
    'Ця дія обмежить автоматичні перевірки AML документами з машинозчитуваними зонами (MRZ), оптимізуючи час обробки, забезпечуючи моніторинг ключових документів. Будь ласка, підтвердьте ваше рішення.',
  AUTOMATIC_AML_START_FOR_ALL_DOCS_TOOLTIP:
    'Якщо автоматичний запуск AML вимкнено, перевірки AML виконуватимуться лише для документів, що містять MRZ дані.',
  AUTOMATIC_AML_STOP_FOR_ALL_DOCS_TITLE: 'Вимкнути автоматичний запуск AML для всіх документів?',
  ENABLE_AUTOMATIC_AML_START_FOR_ALL_DOCS: 'Увімкнути автоматичний запуск AML для всіх документів',
  AUTOMATIC_AML_START_FOR_ALL_DOCS_TITLE: 'Увімкнути автоматичний запуск AML для всіх типів документів?',
  AUTOMATIC_AML_START_FOR_ALL_DOCS_DESCRIPTION:
    'Ця дія ініціює перевірки AML для кожного поданого документа, незалежно від типу документа. Це може збільшити час обробки та використання ресурсів. Будь ласка, підтвердьте ваше рішення.',
  MONITORING_PERIODICITY: 'Періодичність моніторингу',
  YES_TURN_ON: 'Так, увімкнути',
  AUTO_APPROVAL: 'Автоматичне затвердження',
  AUTO_APPROVAL_DESCRIPTION:
    'Дозволяє системі автоматично затверджувати або відхиляти кроки верифікації без вашого втручання.',
  AUTO_APPROVAL_DIALOG_TITLE: 'Підтвердити активацію автоматичного затвердження',
  AUTO_APPROVAL_DIALOG_DESCRIPTION:
    'Увімкнувши автоматичне затвердження, всі кроки верифікації будуть автоматично оброблятися системою, що виключає необхідність ручного перегляду. Ви хочете продовжити цю дію?',

  // PERIODICITY
  DAILY: 'Щодня',
  WEEKLY: 'Один раз на тиждень',
  MONTHLY: 'Один раз на місяць',
  QUARTERLY: 'Щоквартально',
  HALF_EARLY: 'На половину раніше',
  YEARLY: 'Щороку',

  // STYLES
  FONT_FAMILY: 'Сімейство шрифтів',
  BACKGROUND_COLOR: 'Колір фону',
  TEXT_COLOR: 'Колір тексту',
  BORDER_COLOR: 'Колір межі',
  HOVER_BACKGROUND_COLOR: 'Колір фону при наведенні',
  HOVER_TEXT_COLOR: 'Колір тексту при наведенні',

  DESKTOP: 'Робочий стіл',
  ADD_NEW: 'Додати новий',
  ADD_STEP: 'Додати крок',
  WORKFLOW: 'Робочий процес',
  DOC_PHOTO: 'Фото документа',
  YES_DELETE: 'Так, видалити',
  WORKFLOWS: 'Робочі процеси',
  SELECT_ACTIONS: 'Виберіть дії',
  PREVIEW: 'Попередній перегляд',
  ONE_SHOT: 'Знімок одним кадром',
  PHONE_CAMERA: 'Камера телефону',
  THANK_YOU_SCREEN: 'Екран подяки',
  QUESTIONNAIRE_STEP: 'Крок опитування',
  INTRO_SCREEN: 'Початковий екран',
  SEARCH_BY_NAME: 'Пошук за назвою',
  DELIVERY_METHOD: 'Спосіб доставки',
  TYPE_OF_DOCUMENTS: 'Тип документів',
  DESCRIPTION: 'Опис',
  NUMBER_OF_ATTEMPTS: 'Кількість спроб',
  URL_IS_NOT_VALID: 'Url не є дійсним',
  LIVENESS_CHECK: 'Перевірка liveness',
  FACE_MATCHING: 'Співставлення облич',
  OTP_VERIFICATION: 'Підтвердження OTP',
  BACK_TO_LIST: 'Повернутися до списку',
  AUTO_CAPTURE: 'Автоматичне захоплення',
  LIVENESS_RESULT: 'Результати liveness',
  QUESTIONNAIRE_SCHEMA: 'Схема опитування',
  DOCUMENT_CHECK: 'Перевірка документів',
  PERMITTED_COUNTRIES: 'Дозволені країни',
  CAPTURING_DOCS: 'Захоплення документів',
  GALLERY_UPLOAD: 'Завантаження з галереї',
  EXTERNAL_DATABASE: 'Зовнішня база даних',
  START_WORKFLOW: 'Запустити робочий процес',
  ENTER_COUNTRY_NAME: 'Введіть назву країни',
  DELETE_WORKFLOW: 'Видалити робочий процес',
  DELETE_DRAFT_WORKFLOW: 'Видалити чорновий робочий процес',
  URL_OF_THIS_DATABASE: 'URL цієї бази даних',
  EXCLUSION_COUNTRIES: 'Країни для виключення',
  ENTER_DOCUMENT_TYPE: 'Введіть тип документа',
  DUPLICATE_WORKFLOW: 'Дублювати робочий процес',
  ADD_NEW_WORKFLOW: 'Додати новий робочий процес',
  TYPE_OF_LIVENESS_CHECK: 'Тип перевірки liveness',
  SELFIE_WITH_DOCUMENT: 'Селфі з документом',
  ADD_PERMITTED_COUNTRIES: 'Додати дозволені країни',
  FLOW_SUCCESSFULLY_CREATED: 'Робочий процес успішно створено',
  FLOW_SUCCESSFULLY_UPDATED: 'Робочий процес успішно оновлено',
  NO_ADDITIONAL_CONFIGS: 'Немає додаткових налаштувань',
  ADD_EXCLUSION_COUNTRIES: 'Додати країни для виключення',
  SOURCE_FOR_FACE_MATCHING: 'Джерело для перевірки облич',
  SELECT_FLOW_STATUS: 'Виберіть статус повторюваного робочого процесу',
  CONFIRM_FLOW_DELETE: 'Ви впевнені, що хочете видалити робочий процес?',
  CONFIRM_DRAFT_FLOW_DELETE: 'Ви впевнені, що хочете видалити чорновий робочий процес?',

  INFO_STYLES_APPLY_TO_ALL_STEPS: 'Ці стилі застосуються до всіх кроків',

  QUESTIONNAIRE_SCHEMA_IS_REQUIRED: 'Необхідна схема анкети',
  MAX_NAME_LENGTH: 'Назва має містити не більше 50 символів',
  NAME_REQUIRED_FIELD: 'Назва обов`язкове для заповнення',
  NOT_ALL_REQUIRED_FIELDS_FILLED: 'Ви не заповнили всі необхідні поля',
  NAME_UNIQ_ERROR: 'Ця назва робочого процесу <strong>{{name}}</strong> уже існує',
  FACE_MATCH_ERROR_TOOLTIP:
    'Для можливості редагування цього поля, переконайтеся, що крок "Face Match" розташований після кроку <strong>{{stepName}}</strong>.',
  FACE_MATCH_ERROR_LENGTH: 'Потрібно вибрати принаймні 2 елементи!',
  DOCUMENTS_POPPER_CONFIRM_TEXT:
    'Ви впевнені, що хочете видалити всі елементи у полі <strong>{{infoText}}</strong>?',
  TOOLTIP_FONT_FAMILY: 'Просто вставте шрифт Google у вказаному форматі, щоб застосувати обраний стиль.',

  // GENERAL TAB
  COUNTRIES_HINT: 'Set country-based restrictions using IP addresses',

  // LIVENESS
  CUSTOM_3D_TOOLTIP: ' Поєднуйте різні дії (наприклад, рух очей, усмішку) для більш надійної перевірки.',
  ONE_SHOT_TOOLTIP: 'Швидкий процес верифікації в один крок, що підвищує ризик підробки.',
  LIVENESS_3D_TOOLTIP: 'Розширена верифікація за допомогою технології 3D-зображення.',

  // VALIDATION ERRORS
  DOC_SCAN_SHOULD_BE_ADDED: 'Для продовження, будь ласка, додайте скан документу.',
  AT_LEAST_ONE_SERVICE_SHOULD_BE:
    'Будь ласка, додайте хоча б один сервіс верифікації, перед тим як зберегти флоу.',

  // DOCUMENT SETTINGS
  MINIMAL_HOLDER_AGE: 'Мінімальний вік власника (у роках)',
  MINIMAL_HOLDER_AGE_TOOLTIP: 'Вкажіть мінімальний вік власника документа (у роках)',
  SHIFT_EXPIRY_DATE: 'Зсув дати закінчення терміну дії (в місяцях)',
  SHIFT_EXPIRY_DATE_TOOLTIP:
    'Зсув дати закінчення терміну дії в майбутнє або минуле на задану кількість місяців',
  FOCUS_CHECK: 'Перевірка фокусу під час перевірки якості зображення',
  FOCUS_CHECK_TOOLTIP: 'Вимкнути / увімкнути перевірку фокусу під час перевірки якості зображення',
  GLARES_CHECK: 'Перевірка відблисків під час перевірки якості зображення',
  GLARES_CHECK_TOOLTIP: 'Вимкнути / увімкнути перевірку відблисків під час перевірки якості зображення',
  COLORNESS_CHECK: 'Перевірка кольоровості під час перевірки якості зображення',
  COLORNESS_CHECK_TOOLTIP: 'Вимкнути / увімкнути перевірку кольоровості під час перевірки якості зображення',
  DOCUMENT_MINIMUM_INDENT: 'Мінімальний відступ документа',
  DOCUMENT_MINIMUM_INDENT_TOOLTIP:
    'Вкажіть мінімальний відступ (у відсотках від ширини документа) від кутів документа до меж зображення. Якщо кути документа ближчі, ніж вказане значення, статус міститиме помилку в полі documentPosition.',

  // EMPTY STATE
  EMPTY_STATE_DESCRIPTION:
    'Робочі процеси недоступні. Створіть власні верифікаційні процеси для управління етапами верифікації заявників.',

  // OTP
  SMS_MESSAGE: 'SMS-повідомлення',
  PHONE_CALL: 'Телефонний дзвінок',
  CHOOSE_OTP_DELIVERY_METHOD: 'Виберіть спосіб надсилання OTP',
  HLR: 'HLR (реєстрація місцезнаходження будинку)',
  HLR_TOOLTIP:
    'Коли HLR увімкнено, система перевіряє актуальний статус номера телефону без надсилання OTP. Якщо ви вимкнете цей параметр, вам потрібно буде увімкнути OTP для верифікації.',
  ONE_TIME_PASSWORD: 'OTP (одноразовий пароль)',
  EMAIL_VERIFICATION_TOOLTIP:
    'Коли OTP увімкнено, користувачі отримають унікальний код для підтвердження своєї електронної адреси. ',
  PHONE_VERIFICATION_TOOLTIP:
    'Коли OTP увімкнено, користувачі отримають унікальний код для підтвердження свого номера телефону. Вимкнення цього параметра потребує увімкнення HLR.',

  // INTRO
  ADD_YOUR_LINK: 'Додайте своє посилання',
  CONFIRMED_LINKS: 'Підтверджені посилання',
  INTRO_LINK_TITLE:
    'Тут ви можете додати персоналізовані посилання, з якими користувач повинен погодитися перед реєстрацією.',
  PRIVACY_POLICY_URL: 'Політика конфіденційності',
  TERMS_AND_CONDITIONS_URL: 'Правила та умови',
  PRIVACY_POLICY_LABEL: 'Посилання на політику конфіденційності',
  TERMS_AND_CONDITIONS_LABEL: 'Посилання на правила та умови',
  PRIVACY_POLICY_DESCRIPTION:
    'Підтверджуючи, ви заявляєте, що прочитали, зрозуміли та погоджуєтесь із <strong>політикою конфіденційності</strong>',
  TERMS_AND_CONDITIONS_DESCRIPTION:
    'Підтверджуючи, ви заявляєте, що прочитали, зрозуміли та погоджуєтесь із <strong>правилами та умовами</strong>',
};

export default locale;
