import { createSelector, createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { BaseEntityStoreBuilder } from 'app/store/helpers';
import { actions as logsActions } from 'app/main/logs/store/logsSlice';

import transactionMonitoringService from './transactionMonitoringService';

export const TRANSACTION_MONITORING_MODULE_KEY = 'transaction-monitoring';

const storeBuilder = new BaseEntityStoreBuilder(
  TRANSACTION_MONITORING_MODULE_KEY,
  transactionMonitoringService
);
const entityStore = (state) => state[TRANSACTION_MONITORING_MODULE_KEY];

export const selectors = {
  ...storeBuilder.selectors,
  selectId: createSelector(entityStore, (state) => state.id),
  selectComments: createSelector(entityStore, (state) => state.comments),
  selectPdfReport: createSelector(entityStore, (state) => state.pdfReport),
  selectEntityDetails: createSelector(entityStore, (state) => state.entityDetails),
  selectEntityIsLoading: createSelector(entityStore, (state) => state.entityIsLoading),
  selectScoreResultsList: createSelector(entityStore, (state) => state.scoreResultsList),
  selectAddressRiskReport: createSelector(entityStore, (state) => state.addressRiskReport),
  selectScoreResultsListLoading: createSelector(entityStore, (state) => state.scoreResultsListLoading),
};

export const actions = {
  refresh: storeBuilder.createAction('refresh'),
  postComment: storeBuilder.createAction('postComment'),
  getComments: storeBuilder.createAction('getComments'),
  changeStatus: storeBuilder.createAction('changeStatus'),
  getPdfReport: storeBuilder.createAction('getPdfReport'),
  addMonitoring: storeBuilder.createAction('addMonitoring'),
  getAmlDetails: storeBuilder.createAction('getAmlDetails'),
  getScoreResults: storeBuilder.createAction('getScoreResults'),
  getEntitiesList: storeBuilder.createAction('getEntitiesList'),
  getEntityDetails: storeBuilder.createAction('getEntityDetails'),
  removeMonitoring: storeBuilder.createAction('removeMonitoring'),
  assignToApplicant: storeBuilder.createAction('assignToApplicant'),
  getAddressRiskReport: storeBuilder.createAction('getAddressRiskReport'),
  bulkAssignToApplicant: storeBuilder.createAction('bulkAssignToApplicant'),
  clearState: createAction(`${TRANSACTION_MONITORING_MODULE_KEY}/clearState`),
  setAddressRiskReport: createAction(`${TRANSACTION_MONITORING_MODULE_KEY}/setAddressRiskReport`),
  getLogHistory: createAsyncThunk(
    `${TRANSACTION_MONITORING_MODULE_KEY}/getLogHistory`,
    async ({ amlId, queryParams }, { dispatch }) => {
      const logs = await transactionMonitoringService.getLogHistory({ amlId, queryParams });
      const { offset } = queryParams;

      const total = logs?.length || 0;

      if (logs && offset === 0) {
        dispatch(logsActions.setLogs({ rows: logs, total }));
      }
      if (logs && offset > 0) {
        dispatch(logsActions.addLogs({ rows: logs, total }));
      }
    }
  ),
};

const transactionMonitoringSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.getEntityDetails.pending, (state) => {
      state.entityIsLoading = true;
    })
    .addCase(actions.getEntityDetails.fulfilled, (state, action) => {
      state.entityDetails = action.payload;
      state.entityIsLoading = false;
    })
    .addCase(actions.getEntitiesList.fulfilled, storeBuilder.adapter.setAll)
    .addCase(actions.getAddressRiskReport.fulfilled, (state, action) => {
      state.id = action.payload.id;
    })
    .addCase(actions.setAddressRiskReport, (state, action) => {
      state.addressRiskReport = action.payload;
    })
    .addCase(actions.getScoreResults.pending, (state) => {
      state.scoreResultsListLoading = true;
    })
    .addCase(actions.getScoreResults.rejected, (state) => {
      state.scoreResultsListLoading = false;
    })
    .addCase(actions.getScoreResults.fulfilled, (state, action) => {
      state.scoreResultsList = action.payload.rows;
      state.scoreResultsListLoading = false;
    })
    .addCase(actions.getAmlDetails.fulfilled, (state, action) => {
      state.addressRiskReport = action.payload;
    })
    .addCase(actions.addMonitoring.fulfilled, (state, { meta: { arg } }) => {
      state.addressRiskReport = { ...state.addressRiskReport, isMonitoring: true };
      state.scoreResultsList = state.scoreResultsList.map((a) =>
        arg.ids.includes(a.id) ? { ...a, isMonitoring: true } : a
      );
    })
    .addCase(actions.removeMonitoring.fulfilled, (state, { meta: { arg } }) => {
      state.addressRiskReport = { ...state.addressRiskReport, isMonitoring: false };
      state.scoreResultsList = state.scoreResultsList.map((a) =>
        arg.ids.includes(a.id) ? { ...a, isMonitoring: false } : a
      );
    })
    .addCase(actions.getPdfReport.fulfilled, (state, action) => {
      state.pdfReport = action.payload;
    })
    .addCase(actions.refresh.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.addressRiskReport = null;
    })
    .addCase(actions.getComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    })
    .addCase(actions.postComment.fulfilled, (state, action) => {
      state.comments = [action.payload, ...state.comments];
    })
    .addCase(actions.assignToApplicant.fulfilled, (state, action) => {
      state.addressRiskReport = {
        ...state.addressRiskReport,
        applicantId: action?.meta?.arg?.externalUserId,
      };
    })
    .addCase(actions.clearState, (state) => {
      state.id = null;
      state.addressRiskReport = null;
      state.comments = [];
    })
);

export default transactionMonitoringSlice.reducer;
