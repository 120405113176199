import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { socketSelectors } from 'app/store/socketSlice';
import { authSelectors } from 'app/auth/store/userSlice';
import { useEmitter } from 'app/hooks/socket/hooks/useEmitter';
import { useSubscription } from 'app/hooks/socket/hooks/useSubscription';
import { changeMode, selectors as modeSelectors } from 'app/store/modeSlice';

import { PATHS } from '../../verifications/constants';
import { NOTIFICATION_SOCKET_EMIT_ENUM, NOTIFICATION_SOCKET_EVENT_ENUM } from '../constants';

export const useNotifications = () => {
  const emitter = useEmitter();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscribe = useSubscription();
  const [total, setTotal] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const socket = useSelector(socketSelectors.selectConnection);
  const currentMode = useSelector(modeSelectors.selectModeType);
  const currentUserId = useSelector(authSelectors.currentUserId);

  const getNotifications = () => emitter(NOTIFICATION_SOCKET_EMIT_ENUM.GetNotifications);
  const markAllMessageAsRead = () => emitter(NOTIFICATION_SOCKET_EMIT_ENUM.MarkAllMessageAsRead);
  const markMessageAsRead = (messageId) =>
    emitter(NOTIFICATION_SOCKET_EMIT_ENUM.MarkMessageAsRead, { messageId });

  useEffect(() => {
    if (socket) {
      getNotifications().then((response) => {
        const { data } = response;

        setNotifications(data.rows);
        setTotal(data.total);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      const event = NOTIFICATION_SOCKET_EVENT_ENUM.NewNotification;

      const callback = (data) => {
        setNotifications((prev) => {
          if (!_.find(prev, { id: data.id })) {
            return [data, ...prev];
          }
          return prev;
        });
      };

      subscribe(event, callback);

      // Cleanup to avoid duplicate listeners
      return () => socket.off(event);
    }
  }, [socket, notifications, subscribe]);

  const onNotificationClick = useCallback(
    (messageId, isRead, applicantId, workmode) => {
      if (!isRead) {
        markMessageAsRead(messageId).then(() => {
          setNotifications((prev) =>
            prev.map((notification) =>
              notification.id === messageId ? { ...notification, isRead: true } : notification
            )
          );
        });
      }

      if (workmode !== currentMode) {
        dispatch(changeMode(workmode));
      }

      navigate(PATHS.APPLICANTS_DETAILS.replace(':applicantId', applicantId));
    },
    [currentUserId, emitter, currentMode]
  );

  const onMarkAllAsReadClick = useCallback(
    (markedAsRead) => {
      if (!markedAsRead) {
        markAllMessageAsRead().then(() => {
          setNotifications((prev) => prev.map((notification) => ({ ...notification, isRead: true })));
        });
      }
    },
    [currentUserId]
  );

  return { total, notifications, onNotificationClick, onMarkAllAsReadClick };
};
