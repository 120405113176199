import { useCallback, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';

import { useEffectOnce } from 'app/hooks/useEffectOnce';
import { setConnection, setConnected, socketSelectors } from 'app/store/socketSlice';

import * as hooks from './hooks';
import { SOCKET_URL } from './constants';

export const useSocketIo = () => {
  const dispatch = useDispatch();
  const options = hooks.useOptions();
  const connection = useSelector(socketSelectors.selectConnection);

  const onConnect = useCallback(() => {
    dispatch(setConnected(true));
  }, [dispatch]);

  const onDisconnect = useCallback(() => {
    dispatch(setConnected(false));
  }, []);

  useEffectOnce(() => {
    if (connection) {
      connection.disconnect();
      return;
    }

    const newConnection = socketIOClient(SOCKET_URL, options);
    dispatch(setConnection(newConnection));
  }, [options, connection]);

  // disconnect if connection becomes null
  useEffect(() => {
    if (!connection) {
      return;
    }

    return () => {
      connection.disconnect();
    };
  }, [connection]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    connection.on('connect', onConnect);
    connection.on('disconnect', onDisconnect);

    return () => {
      // Clean up listeners to avoid memory leaks
      connection.off('connect', onConnect);
      connection.off('disconnect', onDisconnect);
    };
  }, [onConnect, connection, onDisconnect]);
};
