export default {
  NAME: 'Name',
  PROGRAM: 'Program',
  SOURCES: 'Sources',
  GO_TO_LINK: 'Go to Link',
  SHOW_DOCUMENT: 'Show Document',
  NATIONALITY: 'Nationality',
  NAME_SURNAME: 'Name Surname',
  GENDER: 'Gender',
  BIRTH_INFORMATION: 'Birth Information',
  COUNTRY_NATIONALITY: 'Country&Nationality',
  ORIGINAL_SCRIPTS: 'Original Scripts',
  AKA_NAMES: 'A.K.A. Names',
  PROFILE: 'Profile',
  PROFILE_NOTES: 'Profile Notes',
  UPDATED: 'Updated',
  REMOVED: 'Removed',
  CONFIRM: 'Confirm',
  REQUIRED: 'Required',
  TIMELINE: 'Timeline',
  PHONE: 'Phone',
  MORE: 'More',

  VERIFICATIONS: 'Verifications',
  APPLICANTS: 'Applicants',
  APPLICANT_ID: 'Applicant ID',
  VERIFICATION_DETAILS: 'Verification',

  DOWNLOAD: 'Download',
  CHANGES_HISTORY: 'Changes History',
  HISTORY: 'History',
  LOGS: 'Logs',
  OLD_STEPS: 'Old steps',

  BUTTON_APPROVE: 'Approve',
  BUTTON_DECLINE: 'Decline',
  DELETE: 'Delete',
  REOPEN: 'Reopen',
  REVIEWER: 'Reviewer',
  ASSIGN: 'Assign',
  ASSIGN_TO_ME: 'Assign to me',
  EDIT: 'Edit',
  REJECT: 'Reject',
  ONGOING: 'Ongoing',

  STATUS: 'Status',
  REVIEWER_STATUS: 'Reviewer status',
  TRANSACTION_ID: 'Verification id',
  FULL_NAME: 'Full name',
  UPDATED_AT: 'Date',
  CREATED_AT: 'Date of creation',
  PLATFORM: 'Platform',
  APPLICATION: 'Application',
  RESOLVE_WARNING: 'Resolve',
  CONFIRM_WARNING_RESOLUTION: 'Confirm Warning Resolution',
  CONFIRM_WARNING_RESOLUTION_DESCRIPTION: 'Are you sure you want to resolve the warning for this profile?',

  ALL_STATUS: 'All statuses',
  STATUS_INIT: 'Init',
  STATUS_INCOMPLETE: 'Incomplete',
  STATUS_IN_PROGRESS: 'In progress',
  STATUS_ON_HOLD: 'On hold',
  STATUS_COMPLETED: 'Completed',
  STATUS_IN_REVIEW: 'In Review',
  STATUS_APPROVED: 'Approved',
  STATUS_REJECTED: 'Rejected',
  STATUS_ACCEPTED: 'Accepted',

  REASON: 'Reason',
  ALL_PLATFORM: 'All platforms',
  ANDROID: 'Android',
  IOS: 'IOS',
  WEB: 'Web',
  SPOOFING: 'Spoofing to liveness',

  WRITE_COMMENT: 'Write comment',
  ALL_APP_KEY: 'All applications',
  GENERATE_LINK: 'Generate link',
  REQUEST_ADDITIONAL_INFO: 'Request additional info',
  GENERATE_LINK_TOOLTIP: 'Generate a new link to allow the user to continue the current flow.',
  ADDITIONAL_INFO_TOOLTIP:
    'Request additional info from the Applicant profile. This action starts an on-demand flow and does not conflict with the regular flow.',
  TRUST_LEVEL: 'Trust level',
  PERIODICITY: 'Periodicity',
  VERIFICATION_METHOD: 'Verification Methods',
  QUESTIONNAIRE: 'Questionnaire results',
  EMAIL_VERIFICATION: 'Email Verification',
  PHONE_VOICE_OTP_VERIFICATION: 'Phone voice OTP verification',
  PHONE_VERIFICATION: 'Phone Verification',
  ADDRESS_RISK_SCORE: 'Address risk score',
  DOCUMENT_CAPTURE: 'Document Check',
  CLEAN_DOCUMENT_CAPTURE: 'Clean Document Capture',
  BARCODE_CAPTURE: 'Barcode Capture',
  CREDIT_CARD_CAPTURE: 'Credit Card Capture',
  RFID_DOCUMENT_CAPTURE: 'RFID Document Capture',
  SPECIFIC_DOCUMENT_CAPTURE: 'Specific Document Capture',
  SELFIE_CAPTURE: 'Selfie Capture',
  FACE_CAPTURE: 'Face Capture',
  FACE_MATCH: 'Face Match',
  FACE_LIVENESS: 'Liveness Detection',
  TRANSACTION_RISK_SCORE: 'Transaction Risk Score',
  TRANSACTION_MONITORING: 'Transaction monitoring',
  PASSIVE_LIVENESS: 'Liveness',
  PASSIVE_FACE_MATCH: 'Face Match',
  SIGNATURE: 'Signature',
  OTP_VERIFICATION: 'Otp Verification',
  UTILITY_BILL: 'Utility Bill',
  DIIA: 'Diia',
  AML_RESULTS: 'AML results',
  AML_CHECK_COMPLETED_SUCCESSFULLY: 'AML check completed successfully!',
  AML_CHECK_WAS_UNSUCCESSFULLY: 'AML Check Result: Warning',
  AML_CHECK_COMPLETED_SUCCESSFULLY_DESCRIPTION:
    'We are pleased to inform you that no violations were found during the verification of client account',
  AML_CHECK_WAS_UNSUCCESSFULLY_DESCRIPTION:
    'The individual was flagged in some databases. Please review the findings for further action.',
  AML_CANNOT_STARTED: "AML check cannot start, as the user's Name or Surname are not retrieved.",
  APPLICANT_DUPLICATE_CRYPTO_ADDRESS_TITLE: 'Duplicate crypto address detected',
  APPLICANT_DUPLICATE_CRYPTO_ADDRESS:
    'This crypto address has already been associated with another applicant. Please review the original profiles:',
  USER_INFORMATION: 'User Information',
  YES_CONFIRM: 'Yes, I confirm',
  CONFIRM_VERIFICATION_FLOW_CHANGE: 'Confirm Verification Flow Change',
  CONFIRM_VERIFICATION_FLOW_CHANGE_DESCRIPTION:
    'Are you sure you want to change the verification flow for this applicant?',

  LOW_MATCHING: 'Low matching probability. Looks like there are different people on the photos.',

  COMEBACK: 'Back',

  SELECTED: 'selected',
  TRUST_LEVEL_FROM: 'Trust level from',
  TO: 'to',
  EVENT_TYPE: 'Event type',
  PROBABILITY: 'Probability',

  DOCUMENT_CAPTURE_TABLE_HEADER_VISUAL: 'Visual',
  DOCUMENT_CAPTURE_TABLE_HEADER_MRZ: 'MRZ',
  DOCUMENT_CAPTURE_TABLE_HEADER_RFID: 'RFID',

  NOT_ASSIGNED: 'Not assigned',
  REVIEWER_NOT_ASSIGNED: 'Reviewer not assigned',
  IN_REVIEW: 'Reviewer: ',
  CLEAR_FILTERS: 'Clear filters',
  SEARCH: 'Search',
  SELECT_USER: 'Select user',
  WEBHOOK_DELIVERY_STATE: 'Webhook delivery state',
  NOT_FOUND: 'Not found',
  USER_DELETED: 'User was delete',
  NOT_VALID: 'Not valid',
  VALID: 'Valid',
  DUPLICATE_DOCUMENT: 'Duplicate Document',
  DOCUMENT_EXPIRED: 'Document Expired',
  DUPLICATE_PHONE: 'Duplicate Phone',
  DUPLICATE_EMAIL: 'Duplicate Email',
  ADDITIONAL_DOCUMENT_STEP_ERROR: 'Document Step Error',
  COMMENT_REQUIRED_FIELD: 'Comment is a required field',

  APP_NAME: 'Application',
  ERROR_MESSAGE: 'Error message',
  READ_ONLY: '<strong>Read-only.</strong> You have to be a reviewer to get access to edit this data.',
  APPLICANT_IDS: 'Applicant IDs',
  EXTERNAL_USER_ID: 'External user ID',
  VERIFICATIONS_STEPS: 'Verification steps',
  VERIFICATIONS_TRACKER: 'Tracker',
  PERSONAL_INFO: 'Personal info',
  VERIFICATION_INFO: 'Verification info',
  DEVICE_INFO: 'Device info',
  ME: 'Me',
  UNKNOWN: 'Unknown',
  NO_MATCH: 'No Match',
  POTENTIAL_MATCH: 'Potential Match',
  FALSE_POSITIVE: 'False Positive',
  TRUE_POSITIVE: 'True Positive',
  TRUE_POSITIVE_APPROVE: 'True Positive Approve',
  TRUE_POSITIVE_REJECT: 'True Positive Reject',

  DATE_OF_BIRTH: 'Date of birth',
  LOCATION: 'Location',
  EMAIL: 'Email',
  TEL_NUMBER: 'Tel. number',
  IP: 'IP',

  PERCENTAGE_OF_FACEMATCH: 'Percentage of facematch',
  REASON_FOR_DEVIATION: 'Reason for deviation by',
  DATE_TIME: 'Date & Time',
  INFO: 'Info',
  COMMENTS: 'Comments',
  COMMENT: 'Comment',
  EMAIL_VERIFIED: 'Email verified',
  PHONE_NUMBER_VERIFIED: 'Phone number verified',

  METRO: 'Metro',

  DAILY: 'Everyday',
  WEEKLY: 'Once a week',
  MONTHLY: 'Once a month',
  QUARTERLY: 'Quarterly',
  HALF_EARLY: 'Half yearly',
  YEARLY: 'Yearly',
  ONGOING_MONITORING: 'Ongoing Monitoring',
  AML_MONITORING: 'AML monitoring',
  AML_CHECK: 'AML check',
  TURN_OFF: 'Turn off',
  UPDATE: 'Update',
  CREATE: 'Create',
  CANCEL: 'Cancel',
  APPLY: 'Apply',
  START_AML: 'Start AML',
  APPLICANT_DELETE: 'Applicant successfully deleted',
  TOOLTIP_STATUS_INIT: 'Applicant created, no further action required.',
  TOOLTIP_STATUS_INCOMPLETE: 'Verification initiated but some required details are missing.',
  TOOLTIP_STATUS_IN_PROGRESS: 'Background checks underway after user completion.',
  TOOLTIP_STATUS_ON_HOLD: '',
  TOOLTIP_STATUS_COMPLETED: 'User and system actions completed, verification finished.',
  TOOLTIP_STATUS_IN_REVIEW: 'Verification under review by compliance officer.',
  TOOLTIP_STATUS_APPROVED: 'Verification approved by compliance officer.',
  TOOLTIP_STATUS_REJECTED: 'Verification declined by compliance officer.',
  TOOLTIP_REVIEWER_ASSIGNED: 'Reviewer assigned',

  TRANSACTION_DELETE: 'Transaction deleted',

  // WARNINGS
  ALL_WARNING: 'All warnings',
  FILTER_APPLICANT_DUPLICATE_CRYPTO_ADDRESS: 'Applicant duplicate crypto address',
  FILTER_AML_RESULT_UPDATED: 'AML result updated',
  FILTER_AML_CANNOT_STARTED: 'AML cannot started',
  FILTER_AML_POSITIVE_CASES: 'AML positive cases',
  FILTER_APPLICANT_DUPLICATE_PHONE: 'Applicant duplicate phone',
  FILTER_APPLICANT_DUPLICATE_DOCUMENT: 'Applicant duplicate document',
  FILTER_EXPIRED_DOCUMENT: 'Expired document',
  FILTER_APPLICANT_DUPLICATE_EMAIL: 'Applicant duplicate email',
  FILTER_ADDITIONAL_DOCUMENT_STEP_ERROR: 'Additional document step error',
  APPLICANT_DUPLICATE_PHONE:
    'This phone number is already associated with another applicant. Please verify the information and provide a unique phone number',
  APPLICANT_DUPLICATE_DOCUMENT:
    'This document has already been associated with another applicant. Please review the original profiles: ',
  EXPIRED_DOCUMENT:
    'The document provided has expired. Please ask the applicant to upload a valid and up-to-date document for verification',
  APPLICANT_DUPLICATE_EMAIL:
    'This email address is already associated with another applicant. Please ensure uniqueness and provide a different email address',

  // Schedule applicant delete
  RESTORE_DATA: 'Restore Data',
  CANCEL_KEEP_DATA_DELETED: 'Cancel, keep data deleted',
  RESTORE_APPLICANT_DATA: 'Restore Applicant Data',
  RESTORE_APPLICANT_DATA_DESCRIPTION:
    'This action will restore access to the applicant`s data. By confirming this action, the scheduled deletion will be canceled, and the data will be restored.',
  DATA_WILL_BE_DELETED_1: 'Delete documents, photos, and videos',
  DATA_WILL_BE_DELETED_2: 'Delete personal information',
  DATA_WILL_BE_DELETED_3: 'Delete changes history and logging',
  DATA_WILL_BE_DELETED_4: 'Delete comments',
  SCHEDULE_DELETE: 'Schedule Deletion',
  CANCEL_KEEP_THE_DATA: 'Cancel, keep the data',
  CONFIRM_APPLICANT_DATA_DELETION: 'Confirm Applicant Data Deletion',
  CONFIRM_APPLICANT_DATA_DELETION_DESCRIPTION:
    'The applicant`s data will be permanently removed after 20 days. The deletion will include the following:',
  APPLICANT_WILL_BE_DELETED_DETAILS_WARNING:
    'The applicant`s data will be permanently removed after {{days}} days.',

  // DEACTIVATE SERVICE BUTTON
  DEACTIVATE: 'Deactivate',
  DEACTIVATE_IF_YOU_DISAGREE_THE_RESULT: 'Click, if you disagree with the result.',
  DEACTIVATE_IF_YOU_DISAGREE_THE_RESULT_DESCRIPTION:
    'This action will move the data to History and deactivate the service.',
  DEACTIVATE_SERVICE_CONFIRM: 'Click "Confirm" to deactivate the service.',
  DEACTIVATE_SERVICE_CONFIRM_DESCRIPTION:
    'This action will move the data to History and deactivate the service.',

  // ACTIVATE SERVICE BUTTON
  ACTIVATE: 'Activate',
  ACTIVATE_SERVICE_CONFIRM: 'Click "Confirm" to reactivate the service.',
  ACTIVATE_SERVICE_CONFIRM_DESCRIPTION: 'This action will move the data back from History to active status.',
  ACTIVATE_IF_YOU_DISAGREE_THE_RESULT: 'Click, if you disagree with the result.',
  ACTIVATE_IF_YOU_DISAGREE_THE_RESULT_DESCRIPTION:
    'This action will activate the service and move it from History to active service.',

  // SERVICES EMPTY STATE
  FRAME_1: 'Click Generate link in the tracker',
  FRAME_2: 'Copy the link in the modal window',
  FRAME_3: 'Send the link to the user',
  SERVICES_EMPTY_STATE_DESCRIPTION: 'You have rejected the current verification step',
  SERVICES_EMPTY_STATE_HINT: 'Here`s what you need to do next',

  // APPLICANTS EMPTY STATE
  EMPTY_STATE_TITLE: 'Applicants',
  EMPTY_STATE_DESCRIPTION:
    'No applicants have completed verification yet. Once applicants complete their verification flow, they will appear here.',

  // EMAIL AND PHONE VERIFICATION SERVICE
  VERIFIED_POINT: 'Verified / Not verified',
  VALID_POINT: 'Valid / Not valid',
  IS_DISPOSABLE_POINT: 'Disposal email provider',
  IS_PERSONAL_POINT: 'Personal',
  SMTP_STATUS_POINT: 'SMTP',
  DOMAIN_STATUS_POINT: 'Domain Point',
  HOSTING_PROVIDER_POINT: 'Hosting Provider',
  VALIDATION_POINT: 'Validation Point',
  DETAILS: 'Details',
  YES: 'Yes',
  NO: 'No',
  MOBILE: 'Mobile',
  ROAMING: 'Roaming',
  CURRENT_NETWORK_POINT: 'Current network',
  NUMBER_TYPE: 'Number type',
  ROAMING_COUNTRY_CODE: 'Roaming country code',
  EMAIL_OTP_VERIFICATION: 'Email OTP verification',
  PHONE_OTP_VERIFICATION: 'Phone OTP verification',
  PHONE_HLR_VERIFICATION: 'Phone HLR verification',

  EMAIL_DETAILS: 'Email provided by the applicant',
  PHONE_DETAILS: 'Phone provided by the applicant',
  VALID_DETAILS:
    'This check determines if the provided phone number adheres to standard phone number formats. It ensures the number is correctly formatted and capable of receiving calls or messages.',
  HLR_DETAILS:
    'The HLR check verifies if the phone number is currently registered with a mobile network. It determines whether the phone number is active and in service.',
  MOBILE_DETAILS:
    'This check verifies whether the provided phone number belongs to a mobile network. It distinguishes between mobile numbers and other types of phone numbers (e.g., landline or VoIP).',
  ROAMING_DETAILS:
    'This check indicates whether the phone number is currently being used in a roaming state, meaning it is active in a different country than its home network.',
  CURRENT_NETWORK_DETAILS:
    'Identifies the mobile network or service provider currently associated with the phone number. It shows which telecom operator the user is connected to.',
  NUMBER_TYPE_DETAILS:
    'Categorizes the type of phone number, such as mobile, fixed line (landline), premium rate, toll-free, VoIP, or virtual numbers.',
  ROAMING_COUNTRY_CODE_DETAILS:
    'The country code of the country where the phone number is currently roaming. It compares this against the user’s declared location or IP address.',
  LOCATION_DETAILS:
    'Geolocates the current location of the phone number based on telecom data. This location is often determined via the cell towers to which the phone is connected.',

  VERIFIED_DETAILS:
    'This check confirms whether the email address is verified by the user (e.g., through a verification link sent to the email). ',
  VALID_EMAIL_DETAILS:
    'This check determines whether the email address follows proper syntax and formatting standards (e.g., username@domain.com) and verifies if the domain is valid and exists.',
  DISPOSAL_DETAILS:
    'Checks whether the email address is from a known disposable email provider or services associated with the dark web. ',
  PERSONAL_DETAILS:
    'Determines if the email address belongs to a personal email domain (e.g., Gmail, Yahoo, Outlook) as opposed to a corporate or business domain.',
  SMTP_DETAILS:
    'This check verifies if the email address can successfully receive emails by communicating with the receiving mail server (SMTP). It ensures the mailbox exists and is functioning.',
  DOMAIN_STATUS_DETAILS:
    'Verifies the status of the domain associated with the email address, ensuring the domain is valid, active, and not flagged for malicious activities. This includes checking if the domain is registered or has been suspended.',
  HOSTING_PROVIDER_DETAILS:
    'Identifies the hosting provider for the email`s domain. This helps determine where the email infrastructure is hosted and can indicate whether the provider is reputable or associated with known risk factors.',

  // IP ADRESSES
  DEVICE_INFORMATION: 'Device Information',
  IP_ADDRESSES_AND_ITS_PROPERTIES: 'IP address properties',
  GEOLOCATION_DATA: 'Geolocation data',
  PROVIDER_DETAILS: 'Providers details',
  BROWSER_NAME_AND_VERSION: 'Browser name and versions',
  DEVICE: 'Device',
  CITY: 'City',
  CITY_TOOLTIP:
    'Geolocates the city associated with the IP address based on databases that map IP ranges to specific geographic regions.',
  COUNTRY_CODE: 'Country code',
  COUNTRY_CODE_TOOLTIP:
    'Identifies the country associated with the IP address. The country code (ISO 3166 format) is determined through geolocation databases that track IP ownership.',
  REGION: 'Region',
  REGION_TOOLTIP:
    'This check determines the region (state or province) within the country associated with the IP address. Like the city check, this data comes from IP geolocation services.',
  IP_FORM_VALID: 'Valid',
  IP_FORM_VALID_TOOLTIP:
    'This check verifies if the IP address follows the correct format (IPv4 or IPv6) and is registered with the appropriate internet authorities (such as ARIN, RIPE, etc.).',
  BOGON: 'Bogon',
  BOGON_TOOLTIP:
    'This check determines if the IP address belongs to a bogon range. Bogon IP addresses are non-routable addresses that are not allocated to any legitimate organization, such as private, reserved, or unassigned addresses.',
  HOSTING: 'Hosting',
  HOSTING_TOOLTIP:
    'Identifies whether the IP address is associated with hosting providers, data centers, or cloud infrastructure. This indicates that the IP address may belong to a server rather than an individual user’s personal device.',
  PROXY: 'Proxy',
  PROXY_TOOLTIP:
    'This check detects if the IP address is using a proxy service, which allows users to mask their real IP by routing their connection through another server.',
  VPN: 'VPN',
  VPN_TOOLTIP:
    'This check identifies if the IP address belongs to a VPN (Virtual Private Network) service, commonly used to anonymize the user`s real IP address by encrypting traffic and routing it through a remote server.',
  ISP: 'ISP',
  ISP_TOOLTIP:
    'This check identifies whether the IP address belongs to a consumer Internet Service Provider (ISP), typically used by residential users for home internet connections.',
  PROVIDER_DOMAIN: 'Provider domain',
  PROVIDER_DOMAIN_TOOLTIP:
    'Identifies the domain name of the service provider associated with the IP address, typically the ISP or hosting company.',
  PROVIDER_TYPE: 'Provider type',
  PROVIDER_WEBSITE: 'Provider website',
  PROVIDER_DESCRIPTION: 'Provider description',
  TRUE: 'True',
  FALSE: 'False',

  MONITORING: 'Monitoring',
  PROCESSING_HAS_STARTED:
    'Processing has started: Once an applicant`s document is activated, background checks may take some time to complete. Thank you for your patience.',
};
