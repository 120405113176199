const locale = {
  NEW: 'New',
  TIME: 'Time',
  TRACKER: 'Tracker',
  TYPE: 'Type',
  TEST: 'Test',
  LIVE: 'Live',
  NAME: 'Name',
  EDIT: 'Edit',
  SAVE: 'Save',
  STYLE: 'Style',
  SELFIE: 'Selfie',
  CANCEL: 'Cancel',
  PERIOD: 'Period',
  PALETTE: 'Palette',
  DELETE: 'Delete',
  MOBILE: 'Mobile',
  BUTTON: 'Button',
  CUSTOM: 'Custom',
  DESKTOP: 'Desktop',
  CONFIRM: 'Confirm',
  PREVIEW: 'Preview',
  GENERAL: 'General',
  COMPLETE: 'Complete',
  WORKFLOW: 'Workflow',
  DUPLICATE: 'Duplicate',
  COUNTRIES: 'Countries',
  WORKFLOWS: 'Workflows',
  AML_CHECK: 'AML check',
  WEB_CAMERA: 'Web camera',
  TYPOGRAPHY: 'Typography',
  QUESTIONNAIRE: 'Questionnaire',
  DOCUMENTATION: 'Documentation',
  AUTOMATIC_AML_STOP_FOR_ALL_DOCS_DESCRIPTION:
    'This action will limit automatic AML checks to documents with Machine Readable Zones (MRZ), optimizing processing times while ensuring key documents are monitored. Please confirm your decision.',
  AUTOMATIC_AML_START_FOR_ALL_DOCS_TOOLTIP:
    'Note: If it is switched off, AML checks will only be performed on documents containing MRZ data',
  AUTOMATIC_AML_STOP_FOR_ALL_DOCS_TITLE: 'Disable Automatic AML Start for all documents?',
  ENABLE_AUTOMATIC_AML_START_FOR_ALL_DOCS: 'Enable Automatic AML Start for All Documents',
  AUTOMATIC_AML_START_FOR_ALL_DOCS_TITLE: 'Enable Automatic AML Start for all document types?',
  AUTOMATIC_AML_START_FOR_ALL_DOCS_DESCRIPTION:
    'This action will initiate AML checks for every document submitted, regardless of the document type. It may increase processing times and resource usage. Please confirm your decision.',
  YES_TURN_ON: 'Yes, turn on',
  AUTO_APPROVAL: 'Auto-Approval',
  AUTO_APPROVAL_DESCRIPTION:
    'Allow the system to automatically approve or reject verification steps without your intervention.',
  AUTO_APPROVAL_DIALOG_TITLE: 'Confirm Auto-Approval Activation',
  AUTO_APPROVAL_DIALOG_DESCRIPTION:
    'By enabling auto-approval, all verification steps will be automatically processed by the system, eliminating the need for manual review. Do you want to proceed with this action?',
  MONITORING_PERIODICITY: 'Monitoring periodicity',
  NUMBER_OF_ATTEMPTS: 'Number of attempts',
  TYPE_OF_LIVENESS_CHECK: 'Type of liveness check',

  // PERIODICITY
  DAILY: 'Everyday',
  WEEKLY: 'Once a week',
  MONTHLY: 'Once a month',
  QUARTERLY: 'Quarterly',
  HALF_EARLY: 'Half early',
  YEARLY: 'Yearly',

  // STYLES
  FONT_FAMILY: 'Font family',
  BACKGROUND_COLOR: 'Background color',
  TEXT_COLOR: 'Text color',
  BORDER_COLOR: 'Border color',
  HOVER_BACKGROUND_COLOR: 'Hover background color',
  HOVER_TEXT_COLOR: 'Hover text color',

  ADD_NEW: 'Add new',
  ADD_STEP: 'Add step',
  ONE_SHOT: 'One-shot',
  DOC_PHOTO: 'Doc photo',
  PHONE_CAMERA: 'Phone camera',
  BACK_TO_LIST: 'Back to list',
  SELECT_ACTIONS: 'Select actions',
  SEARCH_BY_NAME: 'Search by name',
  QUESTIONNAIRE_STEP: 'Questionnaire step',
  GALLERY_UPLOAD: 'Gallery-upload',
  DELIVERY_METHOD: 'Delivery method',
  INTRO_SCREEN: 'Intro screen',
  YES_DELETE: 'Yes, delete',
  FACE_MATCHING: 'Face matching',
  START_WORKFLOW: 'Start workflow',
  DOCUMENT_CHECK: 'Document check',
  DELETE_WORKFLOW: 'Delete workflow',
  DELETE_DRAFT_WORKFLOW: 'Delete draft workflow',
  THANK_YOU_SCREEN: 'Thank you screen',
  ADD_NEW_WORKFLOW: 'Add new workflow',
  OTP_VERIFICATION: 'OTP verification',
  LIVENESS_RESULT: 'Liveness results',
  QUESTIONNAIRE_SCHEMA: 'Questionnaire schema',
  LIVENESS_CHECK: 'Liveness check',
  DESCRIPTION: 'Description',
  URL_OF_THIS_DATABASE: 'URL of this database',
  NO_ADDITIONAL_CONFIGS: 'No additional configs',
  SOURCE_FOR_FACE_MATCHING: 'Source for face matching',
  TYPE_OF_DOCUMENTS: 'Type of documents',
  CAPTURING_DOCS: 'Capturing docs',
  AUTO_CAPTURE: 'Auto-capture',
  URL_IS_NOT_VALID: 'Url is not valid',
  EXTERNAL_DATABASE: 'External database',
  DUPLICATE_WORKFLOW: 'Duplicate workflow',
  ENTER_COUNTRY_NAME: 'Enter country name',
  PERMITTED_COUNTRIES: 'Permitted countries',
  EXCLUSION_COUNTRIES: 'Exclusion Countries',
  ENTER_DOCUMENT_TYPE: 'Enter document type',
  SELFIE_WITH_DOCUMENT: 'Selfie with a document',
  ADD_PERMITTED_COUNTRIES: 'Add Permitted countries',
  ADD_EXCLUSION_COUNTRIES: 'Add Exclusion Countries',
  FLOW_SUCCESSFULLY_CREATED: 'Flow successfully created',
  FLOW_SUCCESSFULLY_UPDATED: 'Flow successfully updated',
  CONFIRM_FLOW_DELETE: 'Are you sure you want to delete workflow?',
  CONFIRM_DRAFT_FLOW_DELETE: 'Are you sure you want to delete draft workflow?',
  SELECT_FLOW_STATUS: 'Select the status of the duplicate workflow',
  INFO_STYLES_APPLY_TO_ALL_STEPS: 'These styles will apply to all steps',

  QUESTIONNAIRE_SCHEMA_IS_REQUIRED: 'Questionnaire schema is required',
  MAX_NAME_LENGTH: 'Name must be at most 50 characters',
  NAME_REQUIRED_FIELD: 'Name is required field',
  NOT_ALL_REQUIRED_FIELDS_FILLED: 'You have not filled in all the required fields',
  NAME_UNIQ_ERROR: 'This flow name <strong>{{name}}</strong> already exists',
  FACE_MATCH_ERROR_TOOLTIP:
    'To enable editing for this field, please ensure that the "Face Match" step is positioned after <strong>{{stepName}}</strong> in the flow.',
  FACE_MATCH_ERROR_LENGTH: 'At least 2 items should be selected!',
  DOCUMENTS_POPPER_CONFIRM_TEXT:
    'Are you sure you want to delete all items in the <strong>{{infoText}}</strong> field?',
  TOOLTIP_FONT_FAMILY:
    'Simply paste preferred Google font in the specified format to apply your chosen style',

  // GENERAL TAB
  COUNTRIES_HINT: 'Set country-based restrictions using IP addresses',

  // LIVENESS
  CUSTOM_3D_TOOLTIP: 'Combine various actions (e.g., eye movement, smile) for a more robust check.',
  ONE_SHOT_TOOLTIP: 'A quick, single-step verification process with higher spoofing risk.',
  LIVENESS_3D_TOOLTIP: 'Advanced verification using 3D imaging technology.',

  // VALIDATION ERRORS
  DOC_SCAN_SHOULD_BE_ADDED: 'To proceed, please add a document scan.',
  AT_LEAST_ONE_SERVICE_SHOULD_BE: 'Please add at least one user verification service before saving the flow',

  // DOCUMENT SETTINGS
  MINIMAL_HOLDER_AGE: 'Minimal holder age (in years)',
  MINIMAL_HOLDER_AGE_TOOLTIP: 'Specify the minimal age of the document holder (in years)',
  SHIFT_EXPIRY_DATE: 'Shift expiry date (in months)',
  SHIFT_EXPIRY_DATE_TOOLTIP:
    'Shift the date of expiry into the future or past for the specified number of months',
  FOCUS_CHECK: 'Focus check of the image quality validation',
  FOCUS_CHECK_TOOLTIP: 'Disable / enable the focus check for the image quality validation',
  GLARES_CHECK: 'Glares check of the image quality validation',
  GLARES_CHECK_TOOLTIP: 'Disable / enable the glares check for the image quality validation',
  COLORNESS_CHECK: 'Colorness check for the image quality validation',
  COLORNESS_CHECK_TOOLTIP: 'Disable / enable the colorness check for the image quality validation',
  DOCUMENT_MINIMUM_INDENT: 'Document minimum indent',
  DOCUMENT_MINIMUM_INDENT_TOOLTIP:
    'Specify the minimum indent (as a percentage of document width) from the corners of the document to the borders of the image. If the corners of the document are closer than the specified value, the status will contain an error in the documentPosition field.',

  // EMPTY STATE
  EMPTY_STATE_DESCRIPTION:
    'No workflows available. Create custom verification flows to manage applicant verification steps.',

  // OTP
  SMS_MESSAGE: 'SMS message',
  PHONE_CALL: 'Phone call',
  CHOOSE_OTP_DELIVERY_METHOD: 'Choose how to send OTP',
  HLR: 'HLR (Home Location Register)',
  HLR_TOOLTIP:
    'When HLR is on, the system checks the real-time status of the phone number directly without sending an OTP. If you disable this, you`ll need to enable OTP for verification.',
  ONE_TIME_PASSWORD: 'OTP (One Time Password)',
  EMAIL_VERIFICATION_TOOLTIP:
    'When OTP is on, users will receive a unique code to verify their email address.',
  PHONE_VERIFICATION_TOOLTIP:
    'When OTP is on, users will receive a unique code to verify their phone number. Turning this off will require enabling HLR instead.',

  // TRANSACTION_MONITORING
  CRYPTOCURRENCY: 'Cryptocurrency',
  TRANSACTION_MONITORING: 'Transaction monitoring',

  // STEPS GROUPS
  COMPLIANCE_CHECKS: 'Compliance Checks',
  COMPLIANCE_CHECKS_DESCRIPTION: 'Steps to ensure regulatory compliance.',
  CONTACT_INFORMATION: 'Contact Information',
  CONTACT_INFORMATION_DESCRIPTION: 'Steps to verify your contact details.',
  IDENTITY_VERIFICATION: 'Identity Verification',
  IDENTITY_VERIFICATION_DESCRIPTION: 'Steps that confirm user`s identity',
  ONBOARDING_AND_COMPLETION: 'Onboarding and Completion',
  ONBOARDING_AND_COMPLETION_DESCRIPTION: 'Beginning and closing the process.',

  // transaction
  MAX_ADDRESS_COUNT: 'Maximum addresses count',

  // INTRO
  ADD_YOUR_LINK: 'Add your link',
  CONFIRMED_LINKS: 'Confirmed links',
  INTRO_LINK_TITLE: 'Here, you can add personalized links that the user must agree to before registration.',
  PRIVACY_POLICY_URL: 'Privacy & Policy',
  TERMS_AND_CONDITIONS_URL: 'Terms & Conditions',
  PRIVACY_POLICY_LABEL: 'Link to privacy policy',
  TERMS_AND_CONDITIONS_LABEL: 'Link to terms of conditions',
  PRIVACY_POLICY_DESCRIPTION:
    'By ticking, you are confirming that you have read, understood and agree <strong>privacy policy</strong>',
  TERMS_AND_CONDITIONS_DESCRIPTION:
    'By ticking, you are confirming that you have read, understood and agree <strong>terms of conditions</strong>',
};

export default locale;
