import { createAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { BaseEntityStoreBuilder } from 'app/store/helpers';
import questionnaireService from './questionnaireService';

export const QUESTIONNAIRES_MODULE_KEY = 'questionnaire';

const storeBuilder = new BaseEntityStoreBuilder(QUESTIONNAIRES_MODULE_KEY, questionnaireService);

const entityStore = (state) => state[QUESTIONNAIRES_MODULE_KEY];

export const actions = {
  deleteQuestionnaire: storeBuilder.createAction('deleteQuestionnaire'),
  getAllQuestionnaires: storeBuilder.createAction('getAllQuestionnaires'),
  getQuestionnaireById: createAsyncThunk(
    `${QUESTIONNAIRES_MODULE_KEY}/getQuestionnaireById`,
    async ({ questionnaireId }) => {
      const response = await questionnaireService.getQuestionnaireById(questionnaireId);

      return response;
    }
  ),
  createQuestionnaire: storeBuilder.createAction('createQuestionnaire'),
  updateQuestionnaire: storeBuilder.createAction('updateQuestionnaire'),
  setQuestionnaireKey: createAction(`${QUESTIONNAIRES_MODULE_KEY}/setQuestionnaireKey`),
};

export const selectors = {
  ...storeBuilder.selectors,
  selectQuestionnaire: createSelector(entityStore, (state) => state.questionnaire),
  selectQuestionnaireKey: createSelector(entityStore, (state) => state.questionnaireKey),
};

const questionnaireSlice = storeBuilder.generateSlice((builder) =>
  builder
    .addCase(actions.getQuestionnaireById.fulfilled, (state, action) => {
      state.questionnaire = action.payload;
    })
    .addCase(actions.setQuestionnaireKey, (state, { payload }) => {
      state.questionnaireKey = { itemKey: payload };
    })

    .addCase(actions.getAllQuestionnaires.fulfilled, (state, { payload, meta }) => {
      const { arg } = meta;
      const { offset } = { ...arg };

      if (!offset) {
        return storeBuilder.adapter.setAll(state, payload);
      }
      return storeBuilder.adapter.addMany(state, payload);
    })
    .addCase(actions.deleteQuestionnaire.fulfilled, (state, { meta }) => {
      state.total -= 1;

      return storeBuilder.adapter.removeOne(state, meta.arg);
    })
    .addCase(actions.createQuestionnaire.fulfilled, (state, action) => {
      if (action.meta.arg?.isDuplicate) {
        return storeBuilder.adapter.addOne(state, action);
      }
    })
);

export default questionnaireSlice.reducer;
