const locale = {
  DASHBOARD: 'Аналітика',
  CLIENT: 'Клієнт',
  USERS: 'Користувачі',
  INVITES: 'Запрошення',
  PLATFORMS: 'Платформи',
  SERVICES: 'Послуги',
  VERIFICATIONS: 'Верифікації',
  TRANSACTIONS: 'Транзакції',
  APPLICANTS: 'Заявники',
  INTEGRATION: 'Інтеграція',
  APPLICATIONS: 'Додатки',
  BILLING: 'Рахунки',
  COMPANY: 'Компанія',
  SETTING: 'Налаштування',
  SUBTITLE: 'Ваші налаштування',
  TEAM: 'Моя команда',
  WORKFLOWS: 'Робочі процеси',
  QUESTIONNAIRES: 'Анкети',
  ROLE_MANAGEMENT: 'Управління ролями',
  AML_SCAN: 'AML Cканування',
  SEARCH_PAGE: 'Сторінка пошуку',
  RESULTS: 'Результати',
  TRANSACTION_MONITORING: 'Моніторинг транзакцій',
  ENTITY_EXPLORER: 'Ентіті експлорер',
};

export default locale;
