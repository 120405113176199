import PropTypes from 'prop-types';
import { useState } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { useUpdateEffect } from '@fuse/hooks';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTableHead from '@mui/material/TableHead';
import MuiTablePagination from '@mui/material/TablePagination';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import MuiCheckbox from '@mui/material/Checkbox';

import { DEFAULT_ROWS_PAGINATION } from './constants';
import PagePreloader from './components/PagePreloader';
import EmptyPage from './components/EmptyPage';

const Table = ({
  data,
  total,
  loading,
  columns,
  cellsSx,
  rowHover = true,
  onFetchMoreData,
  onRowClick,
  selectedDataKeys,
  selectDataKey,
  onDataSelect,
  withPagination = true,
  withBorders = false,
  defaultRowsPerPage = 10,
  defaultPageIndex = 0,
  headerToolbar: HeaderToolbar,
  manualPagination = true,
  rowsPerPageOptions = DEFAULT_ROWS_PAGINATION,
}) => {
  const { setPageSize, getTableProps, headerGroups, prepareRow, page, gotoPage } = useTable(
    {
      columns,
      data,
      autoResetPage: true,
      manualPagination,
      initialState: { pageSize: defaultRowsPerPage },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);

  useUpdateEffect(() => {
    gotoPage(pageIndex);

    onFetchMoreData?.({
      offset: pageIndex * rowsPerPage,
      limit: rowsPerPage,
    });
  }, [pageIndex, rowsPerPage]);

  if (!data.length && !loading) {
    return <EmptyPage />;
  }

  if (loading && !data.length) {
    return <PagePreloader />;
  }

  const withCheckBox = Boolean(selectDataKey);
  const hasToolbar = Boolean(HeaderToolbar);

  return (
    <>
      <MuiTableContainer
        sx={
          (withBorders && {
            border: '1px solid',
            borderRadius: '16px',
            borderColor: 'accent.200',
          }) ||
          {}
        }
      >
        {hasToolbar && !!selectedDataKeys.length && HeaderToolbar}

        <MuiTable {...getTableProps()} stickyHeader>
          {(!hasToolbar || (hasToolbar && !selectedDataKeys.length)) && (
            <MuiTableHead>
              {headerGroups.map((headerGroup) => (
                <MuiTableRow {...headerGroup.getHeaderGroupProps()}>
                  {withCheckBox && <MuiTableCell />}

                  {headerGroup.headers.map((column) => (
                    <MuiTableCell
                      sx={{
                        ...cellsSx,
                        ...(withBorders && {
                          borderBottom: '1px solid',
                          borderColor: 'accent.200',
                        }),
                      }}
                      className="whitespace-nowrap"
                      {...column.getHeaderProps(column.sortable && column.getSortByToggleProps())}
                    >
                      {column.render('Header')}

                      {column.sortable && (
                        <MuiTableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      )}
                    </MuiTableCell>
                  ))}
                </MuiTableRow>
              ))}
            </MuiTableHead>
          )}
          <MuiTableBody>
            {page.map((row, index) => {
              const labelId = withCheckBox && `enhanced-table-checkbox-${index}`;

              prepareRow(row);

              return (
                <MuiTableRow
                  hover={rowHover}
                  sx={{
                    backgroundColor: row.original?.highlighted ? 'warning.100' : 'inherit',
                  }}
                  {...row.getRowProps()}
                  onClick={(e) => onRowClick?.(row.original)}
                  className="cursor-pointer"
                >
                  {withCheckBox && (
                    <MuiTableCell
                      padding="checkbox"
                      onClick={(event) => {
                        event.stopPropagation();

                        onDataSelect?.(row.original[selectDataKey]);
                      }}
                    >
                      <MuiCheckbox
                        color="primary"
                        checked={Boolean(selectedDataKeys?.includes(row.original[selectDataKey]))}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </MuiTableCell>
                  )}

                  {row.cells.map((cell) => (
                    <MuiTableCell
                      {...cell.getCellProps()}
                      sx={{
                        ...cellsSx,
                        ...(withBorders && {
                          borderBottom: '1px solid',
                          borderColor: 'accent.200',
                        }),
                      }}
                    >
                      {cell.render('Cell')}
                    </MuiTableCell>
                  ))}
                </MuiTableRow>
              );
            })}
          </MuiTableBody>
        </MuiTable>

        {withPagination && total > DEFAULT_ROWS_PAGINATION[0] && (
          <MuiTablePagination
            component="div"
            count={total}
            page={pageIndex}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={(e, newPage) => setPageIndex(newPage)}
            onRowsPerPageChange={(e) => {
              setPageSize(e.target.value);
              setRowsPerPage(e.target.value);
            }}
          />
        )}
      </MuiTableContainer>
    </>
  );
};

Table.propTypes = {
  onFetchMoreData: PropTypes.func,
  onRowClick: PropTypes.func,
  columns: PropTypes.array.isRequired,
  total: PropTypes.number,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  withPagination: PropTypes.bool,
  startTablePageSize: PropTypes.number,
  startTablePageIndex: PropTypes.number,
  withCheckBox: PropTypes.bool,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  toolbar: PropTypes.node,
};

export default Table;
